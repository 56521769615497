import PropTypes from 'prop-types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import reactToWebComponent from 'react-to-webcomponent';
import { BaSeAccountProfile } from '../src/components/account-profile/account-profile';
import { BaSeRoot } from '../src/components/root/root';

const logPrefix = '[BaSeAccountProfile]';

function WebComponentBaSeAccountProfile({
  // estáticas
  ['client-name']: clientName,
  ['client-id']: clientId,
  ['client-uri']: clientUri,
  ['auth-server-url']: authServerUrl,
  ['auth-realm']: authRealm,
  ['avatar-url']: avatarUrl,
  ['cpe-url']: cpeUrl,

  // runtime
  locale = 'pt-BR',
  keycloak = null,
  authenticated = false,
  userName = '',
  userEmail = '',
  userId = '',
  contextLinks = [],
}) {
  const [, forceUpdateState] = React.useReducer((state) => state + 1, 0);

  React.useEffect(() => {
    if (keycloak) {
      keycloak.onAuthSuccess = () => {
        console.log(logPrefix, '😃 autenticado com sucesso');
        forceUpdateState();
      };

      keycloak.onAuthLogout = () => {
        console.log(logPrefix, '👋 fez o logout');
        forceUpdateState();
      };

      keycloak.onTokenExpired = () => {
        console.log(logPrefix, '🔄 token expirou');
        keycloak
          .updateToken(5)
          .then((refreshed) => {
            if (refreshed) {
              console.log(logPrefix, '😃 token foi atualizado');
            } else {
              console.log(logPrefix, '😃 token continua válido');
            }
            forceUpdateState();
          })
          .catch((error) =>
            console.log(
              logPrefix,
              '😖 deu ruim ao atualizar o token ou a sessão expirou',
              error,
            ),
          )
          .finally(() => console.log(logPrefix, '⚠️ finally updateToken'));
      };
    }
  }, [keycloak]);

  return (
    <BaSeRoot locale={locale} withResetCss={false}>
      <BaSeAccountProfile
        keycloak={keycloak}
        // or
        authServerUrl={authServerUrl}
        authRealm={authRealm}
        avatarUrl={avatarUrl}
        cpeUrl={cpeUrl}
        clientName={clientName}
        clientId={clientId}
        clientUri={clientUri}
        authenticated={authenticated}
        userName={userName}
        userEmail={userEmail}
        userId={userId}
        contextLinks={contextLinks}
      />
    </BaSeRoot>
  );
}

WebComponentBaSeAccountProfile.propTypes = {
  // estáticas
  'client-name': PropTypes.string,
  'client-uri': PropTypes.string,
  'client-id': PropTypes.string,
  'auth-server-url': PropTypes.string,
  'auth-realm': PropTypes.string,
  'avatar-url': PropTypes.string,
  'cpe-url': PropTypes.string,
  // runtime
  locale: PropTypes.oneOf(['pt-BR', 'en']),
  keycloak: PropTypes.any,
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userId: PropTypes.string,
  contextLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

customElements.define(
  'base-account-profile',
  reactToWebComponent(WebComponentBaSeAccountProfile, React, ReactDOM),
);
