import { css } from 'styled-components';
import { BaSeColors } from './colors';
import { ThemeShadowLevel, ThemeShadowsInterface } from './theme-interface';

export const BaSeShadows: ThemeShadowsInterface = {
  profundidade0: 'none',
  profundidade2: ` 0  2px  4px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade4: ` 0  4px  8px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade8: ` 0  8px 12px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade16: `0 16px 32px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade32: `0 32px 30px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade64: `0 64px 40px 0 ${BaSeColors.defaultColors.shadowColor}`,

  focusPrimary: `0 0 4px ${BaSeColors.institucionais.azulSebrae75}`, // TODO Ver se vai ter isso mesmo
  focusError: `0 0 4px ${BaSeColors.feedbackInterface.erro70}`, // TODO Ver se vai ter isso mesmo
  focusValidated: `0 0 4px ${BaSeColors.feedbackInterface.sucesso70}`, // TODO Ver se vai ter isso mesmo
};

export const shadowLevelFactor = 2;
export const minShadowLevel = 2;
export const maxShadowLevel = 64;

export function validateShadowLevel(level: ThemeShadowLevel): boolean {
  return [0, 2, 4, 8, 16, 32, 64].includes(level);
}

export function getShadow(level: ThemeShadowLevel): string {
  if (level < minShadowLevel) {
    level = minShadowLevel;
  }
  if (level > maxShadowLevel) {
    level = maxShadowLevel;
  }
  if (validateShadowLevel(level)) {
    return BaSeShadows[`profundidade${level}`];
  }
  return '';
}

export const elevatedCss = ({
  shadowLevel,
}: {
  shadowLevel: ThemeShadowLevel;
}) => css`
  transform: translateY(
    -${shadowLevel * shadowLevelFactor /*  - shadowLevel */}px
  );
  box-shadow: ${getShadow(
    (shadowLevel * shadowLevelFactor) as ThemeShadowLevel,
  )};
`;

export interface ShadownizedProps {
  elevationOnHoverOrFocus: boolean;
  isElevated: boolean;
  shadowLevel: ThemeShadowLevel;
}
