import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { AlertCoordinates } from '../../hooks/coordinates-from-buddy-and-direction';
import { BaSeIconButton } from '../button/shape-button/icon-button';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import { BaSeSmall1 } from '../typography/small/small1';
import {
  TutorialCardButtonWrapper,
  TutorialCardTriangleProps,
  TutorialPopupCard,
  TutorialPopupCardFooter,
  TutorialPopupCardTitle,
  TutorialPopupCardWrapper,
  TutorialPopupTriangle,
} from './tutorial-popup-styled';

export interface BaSeDialogTutorialStepsAxis {
  x: number;
  y: number;
  width: number;
}

export interface PopupTutorialProps {
  totalSteps: number;
  currentStep: number;
  open: boolean;
  title?: string;
  anchor:
    | string
    | React.ForwardedRef<HTMLElement>
    | BaSeDialogTutorialStepsAxis;
  width?: number;
  dismissible?: boolean;
  content?: () => JSX.Element;
  previousStep: () => void;
  nextStep: () => void;
  onClose: () => void;
}

export const BaSePopupTutorial: React.FC<PopupTutorialProps> = ({
  currentStep,
  totalSteps,
  title,
  open,
  anchor,
  width = 310,
  dismissible = true,
  content,
  previousStep,
  nextStep,
  onClose,
}) => {
  const [positions, setPositions] = React.useState<AlertCoordinates>({});
  const [positionsT, setPositionsT] = React.useState<TutorialCardTriangleProps>(
    {} as TutorialCardTriangleProps,
  );
  const [activeFocusTrap, setActiveFocusTrap] = React.useState<boolean>(false);
  const [activeToFocus, setActiveToFocus] = React.useState<boolean>(false);

  const { getMessage } = React.useContext(BaSeI18nContext);

  const selfRef = React.useRef<HTMLDivElement>(null);

  const canDismiss = React.useMemo(
    () => dismissible || currentStep === totalSteps,
    [dismissible, currentStep, totalSteps],
  );

  const calculateXPosition = React.useCallback(
    (posi: BaSeDialogTutorialStepsAxis) => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const cardHeight =
        selfRef?.current?.getBoundingClientRect?.()?.height ?? 0;
      const idealPosition = posi.x + posi.width / 2 - width / 2;
      const difer = idealPosition + width - windowWidth;
      const isNearBottom = posi.y + cardHeight - windowHeight > 0;
      setPositionsT({
        left: Math.max(width / 2 - 8 + Math.max(difer + 20, 0), 20) + '',
        top: isNearBottom ? cardHeight - 8 + '' : -8 + '',
        degre: isNearBottom ? 60 : 239,
        isOpen: open,
      });
      return {
        x: Math.max(idealPosition - Math.max(difer + 20, 0), 20),
        y: isNearBottom ? posi.y - cardHeight - 8 : posi.y + 24,
      };
    },
    [open, width],
  );

  const getPositionsByQuerySelector = React.useCallback((query: string) => {
    const element = document.querySelector(query);
    const pos = element?.getBoundingClientRect();
    return { x: pos?.x ?? 0, y: pos?.y ?? 0, width: pos?.width ?? 0 };
  }, []);

  const getPositions = React.useCallback(() => {
    if (typeof anchor === 'string') {
      return calculateXPosition(getPositionsByQuerySelector(anchor));
    }
    if ((anchor as React.MutableRefObject<HTMLElement>)?.current) {
      const pos = (
        anchor as React.MutableRefObject<HTMLElement>
      ).current.getBoundingClientRect();
      return calculateXPosition({
        x: pos.x,
        y: pos.y,
        width: pos.width,
      });
    }
    return calculateXPosition(anchor as BaSeDialogTutorialStepsAxis);
  }, [calculateXPosition, anchor, getPositionsByQuerySelector]);

  const handlePositions = React.useCallback(() => {
    const { x, y } = getPositions();
    setPositions({ left: x + '', top: y + '' });
  }, [getPositions]);

  const handleClose = React.useCallback(() => {
    onClose();
    return true;
  }, []);

  React.useEffect(() => {
    handlePositions();
  }, [handlePositions]);

  React.useEffect(() => {
    ['resize', 'scroll'].forEach((events) => {
      window.addEventListener(events, handlePositions);
    });

    return () => {
      ['resize', 'scroll'].forEach((events) => {
        window.removeEventListener(events, handlePositions);
      });
    };
  }, [handlePositions, previousStep, nextStep]);

  React.useEffect(() => {
    setTimeout(() => {
      setActiveFocusTrap(open);
    }, 200);
    if (open && positions.top && !activeToFocus) {
      const topPosition = parseInt(positions.top, 10);
      if (window.innerHeight < topPosition && topPosition > 0) {
        window.scrollTo(0, topPosition - window.innerHeight / 3);
      }
      if (topPosition < 0) {
        window.scrollBy(0, topPosition - 100);
      }
      setActiveToFocus(true);
    }
    if (!open) {
      setActiveToFocus(false);
    }
  }, [open, positions, activeToFocus]);

  return (
    <TutorialPopupCardWrapper
      ref={selfRef}
      isOpen={open && positions.top !== undefined}
      style={{
        top: positions.top + 'px',
        left: positions.left + 'px',
        boxSizing: 'border-box',
      }}
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: canDismiss && handleClose,
          clickOutsideDeactivates: canDismiss && handleClose,
          initialFocus:
            currentStep !== totalSteps
              ? `#BaSe--tutorial-next-step-${currentStep} button`
              : undefined,
        }}
        paused={!activeFocusTrap}
      >
        <TutorialPopupCard
          style={{ boxSizing: 'border-box' }}
          isOpen={open}
          innerWidth={width}
        >
          <TutorialPopupCardTitle>
            <div>
              <BaSeSmall1>
                {getMessage('stepper.counter', currentStep, totalSteps)}
              </BaSeSmall1>
              <BaSeParagraph isBold={true}>{title}</BaSeParagraph>
            </div>
            {(dismissible || currentStep === totalSteps) && (
              <BaSeIconButton
                nameIcon="close-big"
                descriptionIcon={getMessage('buttonIcon.iconDescription.close')}
                onClick={onClose}
                type="tertiary"
              />
            )}
          </TutorialPopupCardTitle>
          {content?.()}
          <TutorialPopupCardFooter>
            {currentStep !== 1 && (
              <TutorialCardButtonWrapper>
                <BaSeIconButton
                  nameIcon="angle-left"
                  descriptionIcon={getMessage(
                    'buttonIcon.iconDescription.previous',
                  )}
                  type="secondary"
                  onClick={previousStep}
                />
              </TutorialCardButtonWrapper>
            )}
            {currentStep !== totalSteps && (
              <TutorialCardButtonWrapper
                id={`BaSe--tutorial-next-step-${currentStep}`}
              >
                <BaSeIconButton
                  nameIcon="angle-right"
                  descriptionIcon={getMessage(
                    'buttonIcon.iconDescription.next',
                  )}
                  onClick={nextStep}
                />
              </TutorialCardButtonWrapper>
            )}
          </TutorialPopupCardFooter>
        </TutorialPopupCard>
      </FocusTrap>
      <TutorialPopupTriangle {...positionsT} />
    </TutorialPopupCardWrapper>
  );
};
