import styled from 'styled-components';
import { BaSeScrollbarCss } from '../../theme/scrollbar';

export const RootWrapper = styled.div.withConfig({
  componentId: 'BaSe--root',
})`
  font-size: 16px;
  line-height: 1;

  &,
  * {
    box-sizing: content-box;
  }

  &,
  div,
  span,
  applet,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  img,
  small,
  strong,
  ol,
  ul,
  li,
  label,
  table,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  aside,
  footer,
  header,
  nav,
  output,
  section {
    vertical-align: baseline;
  }
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }
  ol,
  ul {
    list-style: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  &,
  & * {
    ${BaSeScrollbarCss};
  }
`;
