import * as React from 'react';

export function useOutsideEvent<Element extends HTMLElement>(
  ref: React.RefObject<Element>,
  calbackWhenAreOutside: (isInside: false) => void,
): void {
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      return (
        !ref?.current?.contains?.(event?.target as Node) &&
        calbackWhenAreOutside?.(false)
      );
    };

    const handleKeyEscPressed = (event: KeyboardEvent) => {
      return event.key === 'Escape' && calbackWhenAreOutside?.(false);
    };

    const parent = document;

    parent.addEventListener('mousedown', handleClickOutside);
    parent.addEventListener('keyup', handleKeyEscPressed);

    return () => {
      parent.removeEventListener('mousedown', handleClickOutside);
      parent.removeEventListener('keyup', handleKeyEscPressed);
    };
  }, [ref, calbackWhenAreOutside]);
}
