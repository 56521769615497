interface IdGeneratorResult {
  counter: number;
  hash: string;
}

export type IdGenerator = Generator<
  IdGeneratorResult,
  IdGeneratorResult,
  boolean
>;

export function* idGenerator(): IdGenerator {
  let counter = 0;
  while (true) {
    const random = Math.random().toString(36).substring(3);
    yield {
      hash: `BaSe__${random}${++counter}`,
      counter,
    };
  }
}

export function getNextHash(idSequence: IdGenerator): string {
  return idSequence.next().value.hash;
}

export function getNextCounter(idSequence: IdGenerator): number {
  return idSequence.next().value.counter;
}
