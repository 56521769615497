import * as React from 'react';
import { BaSeLocale, MessageKey, messages } from '../i18n';
import { registerLocale } from 'react-datepicker';
import pt_BR from 'date-fns/locale/pt-BR';
import en from 'date-fns/locale/en-US';

const localePtBR = 'pt-BR';
const localeEn = 'en';
const formatCurrencyPrefixPtBR = 'R$';
const formatCurrencyDecimalSeparatorPtBR = ',';
const formatCurrencyClassSeparatorPtBR = '.';
const formatCurrencyPrefixEn = 'US$';
const formatCurrencyDecimalSeparatorEn = '.';
const formatCurrencyClassSeparatorEn = ',';
const formatNumberBRL = 'BRL';
const formatNumberUSD = 'USD';
const formatNumberBRLCurrency = formatNumberBRL;
const formatNumberUSDCurrency = formatNumberUSD;
const formatNumberMinimumFractionDigits = 2;
const formatNumberMaximumFractionDigits = 2;

export interface I18nProviderProps {
  locale: BaSeLocale;
}

export type MessageReplacementValue = string | number;
export type FormatsNumberCurrencyKey = 'BRL' | 'USD';

export type I18nGetMessage = (
  key: MessageKey,
  ...replacements: MessageReplacementValue[]
) => string;

export interface I18nContextInterface extends I18nProviderProps {
  formats: {
    currency: {
      decimalSeparator: string;
      classSeparator: string;
      prefix: string;
    };
    numberCurrencyKey: FormatsNumberCurrencyKey;
    number: {
      [currencyKey: string]: {
        style: string;
        currency: string;
        minimumFractionDigits: number;
        maximumFractionDigits: number;
      };
    };
  };
  getMessage(
    key: MessageKey,
    // eslint-disable-next-line comma-dangle
    ...replacements: MessageReplacementValue[]
  ): string;
  changeLocale(newLocale: BaSeLocale): void;
  registerDateLocale?(localeName: string): void;
}

export const BaSeI18nContext = React.createContext<I18nContextInterface>({
  locale: localePtBR,
  formats: {
    currency: {
      decimalSeparator: formatCurrencyDecimalSeparatorPtBR,
      classSeparator: formatCurrencyClassSeparatorPtBR,
      prefix: formatCurrencyPrefixPtBR,
    },
    numberCurrencyKey: formatNumberBRL,
    number: {
      [formatNumberBRL]: {
        style: 'decimal',
        currency: formatNumberBRLCurrency,
        minimumFractionDigits: formatNumberMinimumFractionDigits,
        maximumFractionDigits: formatNumberMaximumFractionDigits,
      },
    },
  },
  getMessage: () => '',
  changeLocale: () => {},
});

const initializedDateRegister = {
  [localePtBR]: false,
  [localeEn]: false,
};
const registerLocaleMap = {
  [localePtBR]: pt_BR,
  [localeEn]: en,
};

export const BaSeI18nProvider: React.FC<I18nProviderProps> = ({
  locale: localeDefault,
  children,
}) => {
  const [locale, setLocale] = React.useState(localeDefault);

  const currency = React.useMemo(
    () => ({
      decimalSeparator:
        locale === localePtBR
          ? formatCurrencyDecimalSeparatorPtBR
          : formatCurrencyDecimalSeparatorEn,
      classSeparator:
        locale === localePtBR
          ? formatCurrencyClassSeparatorPtBR
          : formatCurrencyClassSeparatorEn,
      prefix:
        locale === localePtBR
          ? formatCurrencyPrefixPtBR
          : formatCurrencyPrefixEn,
    }),
    [locale],
  );

  const numberCurrencyKey = React.useMemo(
    () => (locale === localePtBR ? formatNumberBRL : formatNumberUSD),
    [locale],
  );

  const number = React.useMemo(
    () => ({
      [numberCurrencyKey]: {
        style: 'decimal',
        currency:
          locale === localePtBR
            ? formatNumberBRLCurrency
            : formatNumberUSDCurrency,
        minimumFractionDigits: formatNumberMinimumFractionDigits,
        maximumFractionDigits: formatNumberMaximumFractionDigits,
      },
    }),
    [locale, numberCurrencyKey],
  );

  const getMessage: I18nGetMessage = React.useCallback(
    (key: MessageKey, ...replacements: MessageReplacementValue[]) => {
      const message = messages?.[key]?.[locale] ?? key;
      if (replacements?.length > 0) {
        if (Array.isArray(replacements[0])) {
          replacements = replacements[0];
        }
        return (
          replacements.reduce((messageReplaced, term, index) => {
            const regExp = new RegExp(`\\{${index}\\}`, 'g');
            return (messageReplaced + '').replace(regExp, term + '');
          }, message) + ''
        );
      }
      return message;
    },
    [locale],
  );

  const registerDateLocale = React.useCallback(
    (localeName: keyof typeof registerLocaleMap) => {
      if (!initializedDateRegister[localeName]) {
        registerLocale(localeName, registerLocaleMap[localeName]);
        initializedDateRegister[localeName] = true;
      }
    },
    [],
  );

  React.useEffect(() => setLocale(localeDefault), [localeDefault]);

  return (
    <BaSeI18nContext.Provider
      value={{
        locale,
        formats: { currency, numberCurrencyKey, number },
        getMessage,
        changeLocale: setLocale,
        registerDateLocale,
      }}
    >
      {children}
    </BaSeI18nContext.Provider>
  );
};

export const BaSeI18nConsumer = BaSeI18nContext.Consumer;
