import { Color } from '../../utils/color-transformation/color';
import { BaSeTheme } from '../../theme';

export const spinnerMap = {
  // TODO verificar cores se estão mapeadas
  primary: {
    darkColor: '#8FB8EF',
    mediumColor: Color.generateMiddleColor('#8FB8EF', '#e0edfb').toString(),
    lightColor: '#e0edfb',
    negative: {
      darkColor: '#005eb8',
      mediumColor: Color.generateMiddleColor('#005eb8', '#f0f3f6').toString(),
      lightColor: '#f0f3f6',
    },
  },
  secondary: {
    darkColor: '#394D60',
    mediumColor: Color.generateMiddleColor('#394D60', '#e0e5eb').toString(),
    lightColor: '#e0e5eb',
    negative: {
      darkColor: '#394D60',
      mediumColor: Color.generateMiddleColor('#394D60', '#e0e5eb').toString(),
      lightColor: '#e0e5eb',
    },
  },
  tertiary: {
    darkColor: '#005eb8',
    mediumColor: Color.generateMiddleColor('#005eb8', '#f0f3f6').toString(),
    lightColor: '#f0f3f6',
    negative: {
      darkColor: '#394D60',
      mediumColor: Color.generateMiddleColor('#394D60', '#e0e5eb').toString(),
      lightColor: '#e0e5eb',
    },
  },
  disabled: {
    darkColor: '#394D60',
    mediumColor: Color.generateMiddleColor('#394D60', '#e0e5eb').toString(),
    lightColor: '#e0e5eb',
  },
};

export const spinnerCustomMap = (color: string, primary?: boolean) => ({
  darkColor: Color.generate(color ?? BaSeTheme.colors.institucionais.azulSebrae)
    ?.generateColorFromCurrentLight(primary ? 0.1 : 0)
    .toString(),
  mediumColor: Color.generate(
    color ?? BaSeTheme.colors.institucionais.azulSebrae,
  )
    ?.applyLightColor(() => 0.85)
    .toString(),
  lightColor: Color.generate(
    color ?? BaSeTheme.colors.institucionais.azulSebrae,
  )
    ?.applyLightColor(() => 0.99)
    .toString(),
});

export const mappedSingleColors = {
  destructive: BaSeTheme.colors.feedbackInterface.erro45,
  confirmation: BaSeTheme.colors.feedbackInterface.sucesso26,
};
