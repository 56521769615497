import styled, { keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { zIndexOverlay } from './modal-styled';

const show = keyframes`
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
`;

interface NotificationDialogProps {
  color: string;
  backgroundColor: string;
}

export const NotificationDialog = styled.div<NotificationDialogProps>`
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  align-items: flex-start;
  padding: 0.75em;
  border-radius: 0.25em;
  box-shadow: ${BaSeTheme.shadows.profundidade16};
  width: 90%;
  max-width: 390px;
  position: fixed;
  overflow: hidden;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: ${zIndexOverlay + 1};
  transition: ${`
    transform
    ${BaSeTheme.transitions.durationNormal}
    ${BaSeTheme.transitions.elasticTimingFunction}
  `};
  background: ${({ backgroundColor }) => backgroundColor};
  animation: ${show} ${BaSeTheme.transitions.durationNormal}
    ${BaSeTheme.transitions.elasticTimingFunction} 1;

  &.exiting {
    transform: translate(-50%, -100%);
  }

  .message {
    flex: 1;
    word-break: break-word;
  }

  h6 {
    color: ${({ color }) => color};
  }

  p {
    color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};
  }

  /*mobileSmall*/
  ${BaSeTheme.breakpoints.mobileSmall.mediaQueryCss(`
    top: unset;
    bottom: 5%;
  `)}
  /*mobile*/
  ${BaSeTheme.breakpoints.mobile.mediaQueryCss(`
    top: unset;
    bottom: 5%;
  `)}
  /*mobileLarge*/
  ${BaSeTheme.breakpoints.mobileLarge.mediaQueryCss(`
    top: unset;
    bottom: 5%;
  `)}
`;
