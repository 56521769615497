import * as React from 'react';
import { BaSeBreakpointProvider } from '../../contexts/breakpoint';
import { BaSeDialogProvider } from '../../contexts/dialog';
import { BaSeI18nProvider } from '../../contexts/i18n';
import { BaSeLocale } from '../../i18n';
import { useCssFonts } from '../../hooks/css-fonts';
import { useResetCss } from '../../hooks/reset-css';
import { RootWrapper } from './styled';

export interface RootProps {
  locale?: BaSeLocale;
  withResetCss?: boolean;
}

export const BaSeRoot: React.FC<RootProps> = ({
  locale = 'pt-BR',
  withResetCss = true,
  children,
}) => {
  useCssFonts();
  useResetCss(withResetCss);

  return (
    <RootWrapper>
      <BaSeI18nProvider locale={locale}>
        <BaSeBreakpointProvider>
          <BaSeDialogProvider>{children}</BaSeDialogProvider>
        </BaSeBreakpointProvider>
      </BaSeI18nProvider>
    </RootWrapper>
  );
};
