export type ColorOutput = 'rgb' | 'hex';

export interface HSLColorMode {
  h: number;
  s: number;
  l: number;
}

export interface RGBColorMode {
  r: number;
  g: number;
  b: number;
}

export class Color {
  static readonly hexColorRegExp =
    /^\#?([a-fA-F0-9]{3}|[a-fA-F0-9]{4}|[a-fA-F0-9]{6}|[a-fA-F0-9]{8})$/;
  static readonly rgbColorRegExp =
    /rgba{0,1}\(\d{1,3}\,\d{1,3}\,\d{1,3}?(\,?(\d{1}|(\d{0,1}\.\d{1,3}))){0,1}\)/g;
  static readonly hslColorRegExp =
    /hsla{0,1}\(\d{1,3}\,\d{1,3}\%\,\d{1,3}\%()?(\,?(\d{1}|(\d{0,1}\.\d{1,3}))){0,1}\)/g;

  readonly thresholdToLightenHover: number = 0.95;
  readonly thresholdToLightenFocus: number = 0.75;
  readonly thresholdToDarkenHover: number = 0.54;
  readonly thresholdToDecreaseDarkValue: number = 0.12;

  r = 0;
  g = 0;
  b = 0;
  opacity = 1;

  constructor(r: number, g: number, b: number, o: number = 1) {
    this.set(r, g, b, o);
  }

  static generate(color: string): Color | null {
    color = color.replace(/\s/g, '');
    if (color.match(Color.hexColorRegExp)) {
      return Color.generateFromHex(color);
    }
    if (color.match(Color.rgbColorRegExp)) {
      return Color.generateFromCSSrgb(color);
    }
    if (color.match(Color.hslColorRegExp)) {
      return Color.generateFromHslString(color);
    }
    return null;
  }

  static generateFromCSSrgb(rgbValue: string): Color | null {
    rgbValue = rgbValue.replace(/\s/g, '');
    const rgb = rgbValue.split('(')[1].split(')')[0].split(',');
    if (rgb.length === 3 || rgb.length === 4) {
      const [r, g, b, o] = rgb;
      return new Color(
        parseInt(r, 10),
        parseInt(g, 10),
        parseInt(b, 10),
        parseFloat(o),
      );
    }
    return null;
  }

  static generateFromHex(hexValue: string): Color | null {
    try {
      if (hexValue.length === 4) {
        hexValue = `#${hexValue[1].repeat(2)}${hexValue[2].repeat(
          2,
        )}${hexValue[3].repeat(2)}`;
      } else if (hexValue.length === 5) {
        hexValue = `#${hexValue[1].repeat(2)}${hexValue[2].repeat(
          2,
        )}${hexValue[3].repeat(2)}${hexValue[4].repeat(2)}`;
      }

      const r = parseInt(hexValue.substring(1, 3), 16);
      const g = parseInt(hexValue.substring(3, 5), 16);
      const b = parseInt(hexValue.substring(5, 7), 16);
      const o = parseInt(hexValue.substring(7, 9), 16);
      if (o) {
        return new Color(r, g, b, o / 255);
      }
      return new Color(r, g, b);
    } catch {
      return null;
    }
  }

  static generateFromHslString(hslValue: string): Color | null {
    try {
      hslValue = hslValue.split(' ').join('');
      const auxColor = new Color(0, 0, 0, 1);
      const [h, s, l, opacity] = hslValue
        .split('(')[1]
        .split(')')[0]
        .split(',')
        .map((i) => parseInt(i.replace('%', ''), 10));
      const { r, g, b } = auxColor.HSVtoRGB(h / 360, s / 100, l / 100);
      return new Color(r, g, b, opacity);
    } catch {
      return null;
    }
  }

  static generateMiddleColor(colorHex1: string, colorHex2: string): Color {
    const val1 = Color.generate(colorHex1);
    const val2 = Color.generate(colorHex2);
    const { h: h1, s: s1, l: l1 } = val1?.hsl() as HSLColorMode;
    const { h: h2, s: s2, l: l2 } = val2?.hsl() as HSLColorMode;
    const newH = (h1 + h2) / 200;
    const newS = (s1 + s2) / 200;
    const newL = (l1 + l2) / 200;
    const auxColor = new Color(0, 0, 0);
    const { r, g, b } = auxColor.HSVtoRGB(newH, newS, newL);
    return new Color(r, g, b);
  }

  static validateColor(color: string): boolean {
    color = color.replace(/\s/g, '');
    return !!(
      color.match(Color.hexColorRegExp) ||
      color.match(Color.rgbColorRegExp) ||
      color.match(Color.hslColorRegExp)
    );
  }

  toString(type: ColorOutput = 'rgb'): string {
    const r = Math.round(this.r);
    const g = Math.round(this.g);
    const b = Math.round(this.b);
    const p = this.opacity;
    if (type === 'rgb') {
      return `rgba(${r}, ${g}, ${b}, ${p ?? 1})`;
    }
    function toHex(color: number): string {
      const hex = color.toString(16);
      if (hex.length === 1) {
        return `0${hex}`;
      }
      return hex;
    }
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(
      parseInt(Math.round(p * 255) + '', 10),
    )}`;
  }

  toStringWithOpacity(opacity: number = 1, type: ColorOutput = 'rgb'): string {
    if (opacity < 0 || opacity > 1) {
      throw Error(
        'Informe um valor entre 0 e 1 para a opacidade. Exemplo: 0.5',
      );
    }
    if (type === 'hex') {
      const opacityHex = parseInt((opacity * 255).toFixed(0), 10).toString(16);
      return `${this.toString(type)}${opacityHex}`;
    }
    return this.toString(type).replace(')', `, ${opacity})`);
  }

  isGrayScale(): boolean {
    return this.r === this.b && this.b === this.g;
  }

  set(r: number, g: number, b: number, o: number = 1): void {
    this.r = this.clamp(r);
    this.g = this.clamp(g);
    this.b = this.clamp(b);
    this.opacity = this.clamp(o, 1);
  }

  hueRotate(angle: number = 0): void {
    angle = (angle / 180) * Math.PI;
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ]);
  }

  grayscale(value: number = 1): void {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ]);
  }

  sepia(value: number = 1): void {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ]);
  }

  saturate(value: number = 1): void {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ]);
  }

  multiply(matrix: number[]): void {
    const newR = this.clamp(
      this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2],
    );
    const newG = this.clamp(
      this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5],
    );
    const newB = this.clamp(
      this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8],
    );
    this.r = newR;
    this.g = newG;
    this.b = newB;
  }

  brightness(value: number = 1): void {
    this.linear(value);
  }
  contrast(value: number = 1): void {
    this.linear(value, -(0.5 * value) + 0.5);
  }

  linear(slope: number = 1, intercept: number = 0): void {
    this.r = this.clamp(this.r * slope + intercept * 255);
    this.g = this.clamp(this.g * slope + intercept * 255);
    this.b = this.clamp(this.b * slope + intercept * 255);
  }

  invert(value: number = 1): void {
    this.r = this.clamp((value + (this.r / 255) * (1 - 2 * value)) * 255);
    this.g = this.clamp((value + (this.g / 255) * (1 - 2 * value)) * 255);
    this.b = this.clamp((value + (this.b / 255) * (1 - 2 * value)) * 255);
  }

  hsl(): HSLColorMode {
    // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
    const r = this.r / 255;
    const g = this.g / 255;
    const b = this.b / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = (max + min) / 2;
    let s = (max + min) / 2;
    const l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100,
    };
  }

  HSVtoRGB(h: number, s: number, l: number): RGBColorMode {
    let r;
    let g;
    let b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (pp: number, qq: number, t: number): number => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return pp + (qq - pp) * 6 * t;
        }
        if (t < 1 / 2) {
          return qq;
        }
        if (t < 2 / 3) {
          return pp + (qq - pp) * (2 / 3 - t) * 6;
        }
        return pp;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  }

  applyLightColor(newValueL: () => number): Color {
    let { h, s } = this.hsl();
    h /= 100;
    s /= 100;
    const l = newValueL();
    const { r, g, b } = this.HSVtoRGB(h, s, l);
    return new Color(r, g, b);
  }

  generateLighterColorToHover(): Color {
    return this.applyLightColor(() => this.thresholdToLightenHover);
  }

  generateLighterColorToFocus(): Color {
    return this.applyLightColor(() => this.thresholdToLightenFocus);
  }

  generateColorFromCurrentLight(tax: number): Color {
    let { h, s, l } = this.hsl();
    h /= 100;
    s /= 100;
    l /= 100;
    l = l + tax;
    const { r, g, b } = this.HSVtoRGB(h, s, l);
    return new Color(r, g, b);
  }

  generateDarkColor(): Color {
    let { h, s, l } = this.hsl();
    h /= 100;
    s /= 100;
    l /= 100;
    l = Math.min(
      this.thresholdToDarkenHover,
      Math.max(
        0,
        l > this.thresholdToDecreaseDarkValue
          ? l - this.thresholdToDecreaseDarkValue
          : l,
      ),
    );
    const { r, g, b } = this.HSVtoRGB(h, s, l);
    return new Color(r, g, b);
  }

  generateOpacityLightString(): string {
    return this.toStringWithOpacity(0.15);
  }

  generateOpacityLightFocusString(): string {
    return this.toStringWithOpacity(0.5);
  }

  clamp(value: number, limit: number = 255): number {
    if (value > limit) {
      value = limit;
    }
    if (value < 0) {
      value = 0;
    }
    return value;
  }
}
