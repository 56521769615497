import { numberWithUnit } from '../../utils/string-utils';

interface ExtractWidthHeightReturnType {
  width: string;
  height: string;
}

export type ExtractWidthHeightType = number | string | undefined;

export function extractWidthHeight(
  size?: ExtractWidthHeightType,
  width?: ExtractWidthHeightType,
  height?: ExtractWidthHeightType,
): ExtractWidthHeightReturnType {
  width = numberWithUnit(size ?? width);
  height = numberWithUnit(size ?? height);

  return {
    width,
    height,
  };
}
