import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import {
  ConfigByAuthServerUrlKey,
  useConfigByAuthServerUrl,
} from '../../hooks/config-by-auth-server-url';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import {
  createButtonFocusColor,
  createColor,
} from '../../utils/color-transformation/calculate-pattern';
import { nameAbbreviation, urlSanitizer } from '../../utils/string-utils';
import {
  extractFullName,
  getAvatarUrl,
} from '../image/avatar/avatar-controller';
import { BaSeIcon } from '../image/icon';
import { BaSeSmall1 } from '../typography/small/small1';
// import { BaSeText } from '../typography/text/text';
import { useQRCode } from '../../hooks/qr-code';
import { BaSeButton } from '../button/button/button';
import { BaSeLogoSebraeRestrict } from '../image/logo-sebrae/logo-sebrae-restrict';
import { BaSeLoadingSpinner } from '../loading/spinner';
import { AccountProfileProps } from './account-profile-props';
import { AvatarOrIconAndNameAndEmail } from './avatar-or-icon-and-name-and-email';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FOCUS_BOX_SHADOW_COLOR,
  BUTTON_HEIGHT,
  BUTTON_HOVER_BACKGROUND_COLOR,
  BUTTON_ICON_SIZE,
  BUTTON_TEXT_COLOR,
  MENU_BACKGROUND_COLOR,
  MENU_BORDER_COLOR,
  MENU_COLOR,
  MENU_EMAIL_COLOR,
  MENU_ICON_SHADOW,
  MENU_ICON_SIZE,
  MENU_NAME_COLOR,
  MENU_SEPARATOR_COLOR,
  MENU_SHADOW,
  TRANSITION,
} from './constants';
import { ContainerButtonStyled } from './container-button-styled';
import { ContainerMenuStyled } from './container-menu-styled';
import { LinkStyled } from './link-styled';
import { WrapperStyled } from './wrapper-styled';

const vCardTemplate = `BEGIN:VCARD
VERSION:4.0
FN:{name}
EMAIL:{email}
X-CPF:{cpf}
END:VCARD`;

const focusHoverConfig = {
  opacity: 0.2,
  l: 0.7,
};

function makeAuthURL(authServerUrl: string, realm: string, path: string): URL {
  const authServerURL = new URL(authServerUrl);
  const authServerWithRealmURL = new URL(
    `${authServerURL.pathname}/realms/${realm}`,
    authServerURL,
  );
  return new URL(
    `${authServerWithRealmURL.pathname}/${path}`,
    authServerWithRealmURL,
  );
}

export const BaSeAccountProfile: React.FC<AccountProfileProps> = ({
  keycloak,
  clientName,
  clientUri: externalClientUri,
  clientId: externalClientId,
  avatarUrl: externalAvatarUrl,
  cpeUrl: externalCpeUrl,
  authServerUrl: externalAuthServerUrl,
  authRealm: externalAuthRealm,
  authenticated: externalAuthenticated = false,
  userName: externalUserName = '',
  userEmail: externalUserEmail = '',
  userId: externalUserId = '',
  contextLinks: externalContextLinks = [],
  foregroundColor = BaSeTheme.colors.defaultColors.white,
  backgroundColor: iconBackColor = BaSeTheme.colors.institucionais.azulSebrae36,
  buttonCollapsed = false,
}: AccountProfileProps) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const { getConfig, configIsInitialized } = useConfigByAuthServerUrl();

  const [menuOpened, setMenuOpened] = React.useState(false);
  const [elementQRCode, setElementQRCode] = React.useState<JSX.Element>();

  const { generate: generateQRCode } = useQRCode();

  const avatarUrl = React.useMemo(() => {
    const whenNotFoundConfig = externalAvatarUrl
      ? urlSanitizer(externalAvatarUrl)
      : undefined;
    if (configIsInitialized) {
      return (
        getConfig({
          authServerUrl: keycloak?.authServerUrl,
          key: 'avatarUrl',
        }) ?? whenNotFoundConfig
      );
    }
    return whenNotFoundConfig;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    externalAvatarUrl,
  ]);

  const cpeUrl = React.useMemo(() => {
    const whenNotFoundConfig = externalCpeUrl
      ? urlSanitizer(externalCpeUrl)
      : undefined;
    if (configIsInitialized) {
      return (
        getConfig({ authServerUrl: keycloak?.authServerUrl, key: 'cpeUrl' }) ??
        whenNotFoundConfig
      );
    }
    return whenNotFoundConfig;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    externalCpeUrl,
  ]);

  const authenticated = React.useMemo(
    () => keycloak?.authenticated ?? externalAuthenticated ?? false,
    [keycloak?.authenticated, externalAuthenticated],
  );

  const authRealm = React.useMemo(
    () => keycloak?.realm ?? externalAuthRealm,
    [keycloak?.realm, externalAuthRealm],
  );

  const clientId = React.useMemo(
    () => keycloak?.clientId ?? externalClientId,
    [keycloak?.clientId, externalClientId],
  );

  const userName = React.useMemo(() => {
    if (configIsInitialized) {
      const tokenKey = getConfig({
        authServerUrl: keycloak?.authServerUrl,
        key: 'jwtAttrName',
      });

      if (tokenKey) {
        const tokenUserNameValue = String(keycloak?.tokenParsed?.[tokenKey]);

        if (tokenUserNameValue) {
          return tokenUserNameValue;
        }
      }
    }

    if (externalUserName !== '') {
      return externalUserName;
    }

    return undefined;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    externalUserName,
  ]);

  const getFromToken = React.useCallback(
    (key: ConfigByAuthServerUrlKey) => {
      if (configIsInitialized) {
        const config = getConfig({
          authServerUrl: keycloak?.authServerUrl,
          key,
        });
        if (config) {
          return String(keycloak?.tokenParsed?.[config]);
        }
      }
      return undefined;
    },
    [
      configIsInitialized,
      getConfig,
      keycloak?.tokenParsed,
      keycloak?.authServerUrl,
    ],
  );

  const userEmail = React.useMemo(
    () => getFromToken('jwtAttrEmail') ?? externalUserEmail,
    [getFromToken, externalUserEmail],
  );

  const userCPF = React.useMemo(
    () => getFromToken('jwtAttrCpf'),
    [getFromToken],
  );

  const userId = React.useMemo(
    () => getFromToken('jwtAttrId') ?? externalUserId,
    [getFromToken, externalUserId],
  );

  const contextLinks = React.useMemo(
    () => (!Array.isArray(externalContextLinks) ? [] : externalContextLinks),
    [externalContextLinks],
  );

  const authServerUrl = React.useMemo(
    () =>
      keycloak?.authServerUrl
        ? urlSanitizer(keycloak.authServerUrl)
        : externalAuthServerUrl
          ? urlSanitizer(externalAuthServerUrl)
          : undefined,
    [keycloak?.authServerUrl, externalAuthServerUrl],
  );

  const clientUri = React.useMemo(
    () =>
      externalClientUri
        ? urlSanitizer(externalClientUri)
        : window.location.href,
    [externalClientUri],
  );

  const authLinks = React.useMemo(() => {
    if (!authServerUrl || !authRealm) {
      return [];
    }

    const accountURL = makeAuthURL(authServerUrl, authRealm, 'account');
    const socialAccountsURL = makeAuthURL(
      authServerUrl,
      authRealm,
      'account/identity',
    );

    if (clientName) {
      accountURL.searchParams.set('referrer_name', clientName);
      socialAccountsURL.searchParams.set('referrer_name', clientName);
    }
    if (clientUri) {
      accountURL.searchParams.set('referrer_uri', clientUri);
      socialAccountsURL.searchParams.set('referrer_uri', clientUri);
    }
    if (clientId) {
      accountURL.searchParams.set('referrer', clientId);
      socialAccountsURL.searchParams.set('referrer', clientId);
    }

    let cpeURL: URL | undefined;
    if (cpeUrl) {
      cpeURL = new URL(cpeUrl);
      if (clientName) {
        cpeURL.searchParams.set('sistema', clientName);
      }
      if (clientUri) {
        cpeURL.searchParams.set('voltar', clientUri);
      }
    }

    return [
      {
        label: getMessage('myProfile.label'),
        url: accountURL.toString(),
      },
      ...(!cpeURL
        ? []
        : [
            {
              label: getMessage('myBusiness.label'),
              url: cpeURL.toString(),
            },
          ]),
      ...(authRealm === 'interno'
        ? []
        : [
            {
              label: getMessage('changePassword.label'),
              onClick(): void {
                const redirectToChangePassword = () => {
                  const passwordURL = makeAuthURL(
                    authServerUrl,
                    authRealm,
                    'account/password',
                  );
                  if (clientName) {
                    passwordURL.searchParams.set('referrer_name', clientName);
                  }
                  if (clientUri) {
                    passwordURL.searchParams.set('referrer_uri', clientUri);
                  }
                  if (clientId) {
                    passwordURL.searchParams.set('referrer', clientId);
                  }
                  window.location.href = passwordURL.toString();
                };
                if (typeof keycloak?.login === 'function') {
                  keycloak
                    .login({ action: 'UPDATE_PASSWORD' })
                    .catch(() => redirectToChangePassword());
                  return;
                }
                redirectToChangePassword();
              },
            },
            {
              label: getMessage('socialAccounts.label'),
              url: socialAccountsURL.toString(),
            },
          ]),
    ];
  }, [
    authRealm,
    authServerUrl,
    clientId,
    clientName,
    clientUri,
    cpeUrl,
    getMessage,
    keycloak?.login,
  ]);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useOutsideEvent<HTMLDivElement>(wrapperRef, setMenuOpened);

  const getLoggedFullName = React.useCallback(() => {
    const whenNotFoundConfig = userName ?? getMessage('myAccount.label');
    if (configIsInitialized) {
      return extractFullName({ getConfig, keycloak }) ?? whenNotFoundConfig;
    }
    return whenNotFoundConfig;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    userName,
    getMessage,
  ]);

  const getLoggedName = React.useCallback(
    () => getLoggedFullName()?.split(' ')?.[0] ?? '',
    [getLoggedFullName],
  );

  const getAvatar = React.useCallback(() => {
    if (configIsInitialized) {
      return getAvatarUrl({ getConfig, avatarUrl, userId, keycloak });
    }
    return undefined;
  }, [
    configIsInitialized,
    getConfig,
    keycloak?.tokenParsed,
    keycloak?.authServerUrl,
    avatarUrl,
    userId,
  ]);

  const authLogin = React.useCallback(() => {
    const redirectToLogin = () => {
      if (!authServerUrl || !authRealm) {
        return;
      }
      const loginURL = makeAuthURL(
        authServerUrl,
        authRealm,
        'protocol/openid-connect/auth',
      );
      loginURL.searchParams.set('response_type', 'code');
      if (clientUri) {
        loginURL.searchParams.set('redirect_uri', clientUri);
      }
      if (clientId) {
        loginURL.searchParams.set('client_id', clientId);
      }
      window.location.href = loginURL.toString();
    };
    if (typeof keycloak?.login === 'function') {
      if (clientUri) {
        keycloak
          .login({ redirectUri: clientUri })
          .catch(() => redirectToLogin());
        return;
      }
      keycloak.login().catch(() => redirectToLogin());
      return;
    }
    redirectToLogin();
  }, [authRealm, authServerUrl, clientId, clientUri, keycloak?.login]);

  const authLogout = React.useCallback(() => {
    const redirectToLogout = () => {
      if (!authServerUrl || !authRealm) {
        return;
      }
      const logoutURL = makeAuthURL(
        authServerUrl,
        authRealm,
        'protocol/openid-connect/logout',
      );
      if (clientUri) {
        logoutURL.searchParams.set('post_logout_redirect_uri', clientUri);
      }
      if (clientId) {
        logoutURL.searchParams.set('client_id', clientId);
      }
      window.location.href = logoutURL.toString();
    };

    if (typeof keycloak?.logout === 'function') {
      if (clientUri) {
        keycloak
          .logout({ redirectUri: clientUri })
          .catch(() => redirectToLogout());
        return;
      }
      keycloak.logout().catch(() => redirectToLogout());
      return;
    }
    redirectToLogout();
  }, [authRealm, authServerUrl, clientUri, clientId, keycloak?.logout]);

  const getQRCodeContent = React.useCallback(() => {
    return vCardTemplate
      .replace('{name}', getLoggedFullName())
      .replace('{email}', userEmail)
      .replace('{cpf}', userCPF ?? '');
  }, [getLoggedFullName, userEmail, userCPF]);

  const getQRCodeDescription = React.useCallback(() => {
    return getMessage(
      'myQRCode.description',
      getLoggedFullName(),
      userCPF ?? '',
      userEmail,
    );
  }, [getLoggedFullName, userEmail, userCPF]);

  if (!configIsInitialized) {
    return (
      <BaSeLoadingSpinner
        diameter={BUTTON_ICON_SIZE}
        color={foregroundColor}
        description={getMessage('loading.description')}
      />
    );
  }

  if (authenticated) {
    return (
      <WrapperStyled ref={wrapperRef} wrapperHeight={BUTTON_HEIGHT}>
        <ContainerButtonStyled
          isAuthenticated={authenticated}
          type="button"
          onClick={() => setMenuOpened((opened) => !opened)}
          hoverBackgroundColor={
            createColor(foregroundColor, focusHoverConfig) ??
            BUTTON_HOVER_BACKGROUND_COLOR
          }
          focusBoxShadowColor={
            createButtonFocusColor(foregroundColor) ??
            BUTTON_FOCUS_BOX_SHADOW_COLOR
          }
          backgroundColor={BUTTON_BACKGROUND_COLOR}
          iconSize={BUTTON_ICON_SIZE}
          textColor={foregroundColor}
          iconColor={iconBackColor}
          transition={TRANSITION}
        >
          <AvatarOrIconAndNameAndEmail
            avatar={getAvatar() ?? ''}
            icon={userName ? nameAbbreviation(getLoggedFullName()) : undefined}
            name={buttonCollapsed ? '' : getLoggedName()}
          />
        </ContainerButtonStyled>
        <ContainerMenuStyled
          className={menuOpened ? '' : 'BaSe--account-profile-hidden'}
          aria-label={getMessage('menuAccountProfile.ariaLabel')}
          contextLinksLength={contextLinks.length}
          shownQRCode={!!elementQRCode}
          wrapperHeight={BUTTON_HEIGHT}
          backgroundColor={MENU_BACKGROUND_COLOR}
          borderColor={MENU_BORDER_COLOR}
          textColor={MENU_COLOR}
          textEmailColor={MENU_EMAIL_COLOR}
          iconShadow={MENU_ICON_SHADOW}
          iconSize={MENU_ICON_SIZE}
          textNameColor={MENU_NAME_COLOR}
          separatorColor={MENU_SEPARATOR_COLOR}
          menuShadow={MENU_SHADOW}
          transition={TRANSITION}
        >
          <ul className="BaSe--account-profile-menu-wrapper" role="menu">
            <li className="BaSe--account-profile-avatar-and-name-email">
              <AvatarOrIconAndNameAndEmail
                avatar={getAvatar() ?? ''}
                icon={
                  userName ? nameAbbreviation(getLoggedFullName()) : undefined
                }
                name={getLoggedFullName()}
                email={userEmail}
                onClose={() => setMenuOpened(false)}
              />
            </li>
            {elementQRCode ? (
              <>
                <li className="BaSe--account-profile-qr-code">
                  {elementQRCode}
                </li>
                <li className="BaSe--account-profile-qr-code-button">
                  <BaSeButton
                    leftIcon="angle-left-alt"
                    value={getMessage('myQRCode.back')}
                    type="secondary"
                    color={MENU_COLOR}
                    onClick={() => setElementQRCode(undefined)}
                  />
                </li>
              </>
            ) : (
              <>
                <li className="BaSe--account-profile-qr-code-button">
                  <BaSeButton
                    leftIcon="device/qr-code-line"
                    value={getMessage('myQRCode.generate')}
                    type="secondary"
                    color={MENU_COLOR}
                    isDisabled={!userName}
                    onClick={() => {
                      generateQRCode({
                        content: getQRCodeContent(),
                        description: getQRCodeDescription(),
                        id: 'BaSe--meu-qr-code-sebrae',
                        errorCorrectionLevel: 'high',
                        logo: <BaSeLogoSebraeRestrict />,
                      })
                        .then(setElementQRCode)
                        .catch(() => setElementQRCode(undefined));
                    }}
                  />
                </li>

                {contextLinks?.map((link) => (
                  <li
                    role="menuitem"
                    className="BaSe--account-profile-context-link"
                    key={link.label}
                  >
                    <LinkStyled
                      textColor={MENU_COLOR}
                      as="a"
                      title={link.label}
                      href={link.url}
                    >
                      {link.label}
                    </LinkStyled>
                  </li>
                ))}

                <li
                  className="BaSe--account-profile-separator"
                  aria-hidden="true"
                />

                {authLinks.map((link) => (
                  <li
                    role="menuitem"
                    className="BaSe--account-profile-auth-link"
                    key={link.label}
                  >
                    <LinkStyled
                      textColor={MENU_COLOR}
                      as={link.onClick ? 'button' : 'a'}
                      title={link.label}
                      href={link.url}
                      type={link.onClick ? 'button' : undefined}
                      onClick={link.onClick}
                    >
                      {link.label}
                    </LinkStyled>
                  </li>
                ))}

                <li role="menuitem" className="BaSe--account-profile-auth-link">
                  <LinkStyled
                    textColor={MENU_COLOR}
                    as="button"
                    title={getMessage('logout.label')}
                    type="button"
                    onClick={authLogout}
                  >
                    {getMessage('logout.label')}
                  </LinkStyled>
                </li>
              </>
            )}
          </ul>
        </ContainerMenuStyled>
      </WrapperStyled>
    );
  }

  return (
    <ContainerButtonStyled
      isAuthenticated={authenticated}
      type="button"
      onClick={authLogin}
      hoverBackgroundColor={
        createColor(foregroundColor, focusHoverConfig) ??
        BUTTON_HOVER_BACKGROUND_COLOR
      }
      focusBoxShadowColor={
        createButtonFocusColor(foregroundColor) ?? BUTTON_FOCUS_BOX_SHADOW_COLOR
      }
      backgroundColor={BUTTON_BACKGROUND_COLOR}
      iconSize={BUTTON_ICON_SIZE}
      textColor={BUTTON_TEXT_COLOR}
      iconColor={MENU_COLOR}
      transition={TRANSITION}
      disabled={!authServerUrl || !authRealm}
    >
      <BaSeIcon
        size={BUTTON_ICON_SIZE}
        name="user"
        color={foregroundColor}
        description={getMessage('imageAccountProfile.description')}
      />
      <BaSeSmall1
        isBold={true}
        isItalic={false}
        isThin={false}
        color={foregroundColor}
      >
        {buttonCollapsed ? '' : getMessage('login.label')}
      </BaSeSmall1>
    </ContainerButtonStyled>
  );
};

BaSeAccountProfile.displayName = 'BaSeAccountProfile';
