import * as React from 'react';

export function useDebounce<State>(state: State, delay: number): State {
  const [debouncedValue, setDebouncedValue] = React.useState(state);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(state);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [state, delay]);

  return debouncedValue;
}
