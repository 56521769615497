import * as React from 'react';

import { BaSeProgressElement } from '../../../elements/progress-element';
import { useProgress } from '../../../hooks/progress';
import { BaSeTheme } from '../../../theme';
import { ProgressCircleWrapper } from './progress-circle-styled';
import { ProgressProps } from './progress-props';

interface ProgressCircleProps extends Required<Pick<ProgressProps, 'type'>> {
  size?: number;
  children: React.ReactNode;
  progress?: number;
  trackColor?: string;
  trackWidth?: number;
  loadingDuration?: number;
  indicatorWidth?: number;
  indicatorColor?: string;
  closeOnTimeout?: boolean;
  onFinish?: () => void;
}

export const BaSeProgressCircle = React.forwardRef<
  BaSeProgressElement,
  ProgressCircleProps
>(
  (
    {
      closeOnTimeout = false,
      loadingDuration = 3000,
      indicatorWidth = 3,
      indicatorColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
      size = 100,
      trackColor = BaSeTheme.colors.institucionais.cinzaSebrae90,
      trackWidth = 3,
      type = 'emptying',
      children,
      onFinish,
    }: React.PropsWithChildren<ProgressCircleProps>,
    ref: React.ForwardedRef<BaSeProgressElement>,
  ) => {
    const { progress, loading } = useProgress({
      loadingDuration,
      closeOnTimeout,
      type,
      onFinish,
      ref,
    });

    const center = size / 2;
    const radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
    const dashArray = 2 * Math.PI * radius;
    const dashOffset = dashArray * ((100 - progress) / 100);

    return (
      <>
        {(loading || !closeOnTimeout) && (
          <ProgressCircleWrapper>
            <div>
              <svg
                className="BaSe--progress-circle-progress-line"
                width={size}
                height={size}
              >
                <circle
                  cx={center}
                  cy={center}
                  fill="transparent"
                  r={radius}
                  stroke={trackColor}
                  strokeWidth={trackWidth}
                />

                <circle
                  cx={center}
                  cy={center}
                  fill="transparent"
                  r={radius}
                  stroke={indicatorColor}
                  strokeDasharray={dashArray}
                  strokeDashoffset={dashOffset}
                  strokeLinecap="round"
                  strokeWidth={indicatorWidth}
                />
              </svg>
              <div>{children}</div>
            </div>
          </ProgressCircleWrapper>
        )}
      </>
    );
  },
);

BaSeProgressCircle.displayName = 'BaSeProgressCircle';
