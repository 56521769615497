import * as React from 'react';
import { useCoordinatesFromBuddyAndDirectionToAlert } from '../../../hooks/coordinates-from-buddy-and-direction';
import { useDebounce } from '../../../hooks/debounce';
import { BaSeSmall1 } from '../../typography/small/small1';
import { BaSeSmall2 } from '../../typography/small/small2';
import { AlertProps } from '../alerts-props';
import {
  getTriangleMapper,
  getTriangleMapperSmaller,
  TooltipColorMapper,
} from './tooltip-map';
import {
  MessageWrapper,
  TooltipBody,
  TooltipContainer,
  TooltipTriangle,
  TooltipTriangleSmaller,
} from './tooltip-styled';

export type TooltipProps = Omit<AlertProps, 'title' | 'handleClose'>;

export type EmbedTooltipProps = Partial<
  Omit<TooltipProps, 'open' | 'buddyRef' | 'refOutput'>
>;

export const BaSeTooltip: React.FC<TooltipProps> = ({
  width,
  message = '',
  messageNoBreakLine = false,
  size = 'medium',
  direction = 'neutral',
  color = 'default',
  themeMode = 'light',
  coordinates = {},
  delayToOpen = 0,
  open: externalOpen = false,
  buddyRef,
  refOutput = () => {},
  overrideColors,
}) => {
  const open = useDebounce(externalOpen, externalOpen ? delayToOpen : 0);

  const messages = Array.isArray(message) ? message : [message];

  const mappedColor = React.useCallback(
    () => TooltipColorMapper[color as keyof typeof TooltipColorMapper] ?? color,
    [color, TooltipColorMapper],
  );

  const TriangleProps = React.useCallback(
    () =>
      getTriangleMapper(
        themeMode,
        mappedColor(),
        direction,
        overrideColors?.borderColor,
        overrideColors?.backgroundColor,
      ),
    [getTriangleMapper, themeMode, mappedColor, direction, overrideColors],
  );

  const TrianglePropsSmaller = React.useCallback(
    () =>
      getTriangleMapperSmaller(
        themeMode,
        mappedColor(),
        direction,
        overrideColors?.backgroundColor,
      ),
    [direction, mappedColor, themeMode, overrideColors],
  );

  const { top, left, bottom, right } =
    useCoordinatesFromBuddyAndDirectionToAlert(
      buddyRef,
      direction,
      coordinates,
    );

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref?.current) {
      refOutput(ref?.current);
    }
  }, [ref, message]);

  return (
    <TooltipContainer
      aria-hidden={!open}
      open={open}
      ref={ref}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      width={width}
    >
      <TooltipTriangle {...TriangleProps()} />
      <TooltipTriangleSmaller {...TrianglePropsSmaller()} />
      <TooltipBody
        overrideColors={overrideColors}
        size={size}
        color={mappedColor()}
        themeMode={themeMode}
      >
        {messages.map((item, index) => (
          <MessageWrapper
            key={index}
            messageNoBreakLine={messageNoBreakLine}
            color={mappedColor()}
            overrideColors={overrideColors}
            themeMode={themeMode}
          >
            {size === 'small' ? (
              <BaSeSmall2>{item}</BaSeSmall2>
            ) : (
              <BaSeSmall1>{item}</BaSeSmall1>
            )}
          </MessageWrapper>
        ))}
      </TooltipBody>
    </TooltipContainer>
  );
};

BaSeTooltip.displayName = 'BaSeTooltip';
