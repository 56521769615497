import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';
import { objectNotEmpty } from '../../../utils/object-utils';
import { BaSePopover } from '../../alerts/popover/popover';
import { BaSeTooltip } from '../../alerts/tooltip/tooltip';
import { BaSeIcon } from '../../image/icon';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import { ButtonProps as AllButtonProps } from '../button-props';
import {
  buttonOverrideColor,
  mapButtonHeight,
  mapButtonValues,
  mapCustomColorsButtonValues,
  styleMapSize,
} from '../map-button-style';
import { BaSeTextButton } from '../text-button/text-button';
import * as Styled from './button-styled';
import { getFormattedDataset } from '../../../utils/dataset-utils';
export interface ButtonProps
  extends Omit<
    AllButtonProps,
    | 'direction'
    | 'isBig'
    | 'nameIcon'
    | 'descriptionIcon'
    | 'shape'
    | 'sideButton'
    | 'sizeIcon'
    | 'textColor'
  > {
  disableTextColorHover?: boolean;
}

export const BaSeButton: React.FC<ButtonProps> = ({
  id,
  name,
  dataset,
  type = 'primary',
  value = '',
  size = 'medium',
  width = 'auto',
  rightIcon = '',
  leftIcon = '',
  isBold = false,
  spacedIcons = false,
  isDisabled = false,
  buttonType = 'button',
  color = 'default',
  autoFocus = false,
  isLoading = false,
  loadingText = 'Carregando…',
  tooltip = {},
  popover = {},
  overrideColors,
  disableTextColorHover = false,
  descriptionLeftIcon,
  descriptionRightIcon,
  borderRadius,
  onClick,
  onFocus,
  onBlur,
}) => {
  if (objectNotEmpty(tooltip) && objectNotEmpty(popover)) {
    throw Error('[BaSeButton] error: Informe só o "tooltip" ou o "popover"!');
  }

  const [innerHeight, setInnerHeight] = React.useState<number | undefined>(
    undefined,
  );

  const formattedDataset = getFormattedDataset(dataset);

  const fontWeigh = React.useCallback(
    () => (isBold ? 'bold' : 'normal'),
    [isBold],
  );
  const justifyContent = React.useCallback(
    () =>
      spacedIcons && (!!leftIcon || !!rightIcon) ? 'space-between' : 'center',
    [spacedIcons, leftIcon, rightIcon],
  );
  const minWidth = React.useCallback(
    () => (Number(!!leftIcon) + Number(!!rightIcon)) * 30 + 50,
    [leftIcon, rightIcon],
  );
  const sizeValues = React.useCallback(
    () => styleMapSize[size] ?? styleMapSize.medium,
    [size],
  );

  const replaceObjects = React.useCallback(
    (originalColors: any) => {
      if (!overrideColors) {
        if (disableTextColorHover) {
          originalColors.style.colorHover = originalColors.color;
        }
        return originalColors;
      }
      const overrides =
        buttonOverrideColor(overrideColors)?.[type] ??
        buttonOverrideColor(overrideColors)?.primary;
      const finalObj: any = {};
      for (const key in originalColors) {
        if (key === 'style') {
          finalObj.style = {};
          // eslint-disable-next-line guard-for-in
          for (const style in originalColors.style) {
            finalObj.style[style] =
              overrides?.style?.[style] || originalColors.style[style];
          }
        } else {
          finalObj[key] = overrides[key] || originalColors[key];
        }
      }
      if (disableTextColorHover) {
        finalObj.style.colorHover = finalObj.color;
      }
      return finalObj;
    },
    [color, type, overrideColors, disableTextColorHover],
  );

  const getColorsAtributes = React.useCallback(() => {
    if (color) {
      if (!ColorMapper.isValidType(color)) {
        return (
          mapCustomColorsButtonValues(
            color,
            disableTextColorHover,
            overrideColors,
          )?.[type] ??
          mapCustomColorsButtonValues(
            color,
            disableTextColorHover,
            overrideColors,
          ).primary
        );
      } else {
        return replaceObjects(
          mapButtonValues[type]?.[color as string] ??
            mapButtonValues.primary?.[color as string],
        );
      }
    } else {
      return replaceObjects(
        mapButtonValues[type]?.default ?? mapButtonValues.primary?.default,
      );
    }
  }, [
    ColorMapper,
    mapButtonValues,
    type,
    color,
    overrideColors,
    disableTextColorHover,
    replaceObjects,
  ]);

  const buttonValues = React.useCallback(
    () =>
      isDisabled
        ? color === 'negative'
          ? mapButtonValues[type].negative?.disabled
          : mapButtonValues[type]?.disabled ?? mapButtonValues.primary.disabled
        : getColorsAtributes(),
    [type, getColorsAtributes, mapButtonValues, isDisabled],
  );

  const {
    style: { bgC, bcCHover, boxShadowFocus, border, colorHover },
    iconColor,
    color: buttonColor,
  } = buttonValues();

  const { fontSize, lineHeight, padding, loadingSize } = sizeValues();

  const [isTooltipOrPopoverOpen, setIsTooltipOrPopoverOpen] =
    React.useState(false);
  const { getMessage } = React.useContext(BaSeI18nContext);

  const [hasHover, setHasHover] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!isLoading) {
        buttonRef?.current?.focus();
        onClick(e);
        setTimeout(() => setIsTooltipOrPopoverOpen(false), 1);
      }
    },
    [isLoading, buttonRef, onClick],
  );

  const checkHover = React.useCallback(
    (defaultColor: string) => {
      return (type !== 'primary' ||
        (type === 'primary' && color === 'negative')) &&
        hasHover &&
        !!colorHover
        ? colorHover
        : defaultColor;
    },
    [type, hasHover, colorHover],
  );

  const getBorderRadiusBasedHeight = React.useCallback(() => {
    const mappedHeight = mapButtonHeight[size] ?? mapButtonHeight.medium;
    return (innerHeight ?? mappedHeight) / 2;
  }, [innerHeight, size]);

  const handleGetBorderRadius = React.useMemo(
    () =>
      borderRadius === 'max' ? getBorderRadiusBasedHeight() : borderRadius,
    [borderRadius, getBorderRadiusBasedHeight],
  );

  React.useEffect(() => {
    setInnerHeight(buttonRef?.current?.offsetHeight);
  }, [buttonRef]);

  return (
    <Styled.Button
      id={id}
      name={name}
      {...formattedDataset}
      ref={buttonRef}
      autoFocus={autoFocus}
      disabled={isDisabled}
      type={buttonType}
      backgroundColor={bgC}
      backgroundOnHover={bcCHover}
      border={border}
      hideBorder={type === 'secondary' || color === 'negative'}
      padding={padding}
      width={width}
      minWidth={minWidth()}
      justifyContent={justifyContent()}
      boxShadowFocus={boxShadowFocus}
      borderRadius={handleGetBorderRadius as number}
      aria-label={isLoading ? loadingText : value}
      boxShadow={type !== 'tertiary' && color !== 'negative'}
      thickBorder={color === 'negative'}
      medium={size === 'medium'}
      onClick={handleClick}
      onMouseEnter={() => {
        setHasHover(true);
        setIsTooltipOrPopoverOpen(true);
      }}
      onMouseLeave={() => {
        setHasHover(false);
        setIsTooltipOrPopoverOpen(false);
      }}
      onFocus={(e) => {
        setHasHover(true);
        onFocus?.(e);
        setIsTooltipOrPopoverOpen(true);
      }}
      onBlur={(e) => {
        setHasHover(false);
        onBlur?.(e);
        setIsTooltipOrPopoverOpen(false);
      }}
    >
      {isLoading ? (
        <>
          <Styled.SpinnerContainer size={size}>
            <BaSeLoadingSpinner
              description={getMessage('loading.description')}
              diameter={loadingSize}
              color={color}
              type={type}
              disabled={isDisabled}
            />
          </Styled.SpinnerContainer>
          <BaSeTextButton
            fontSize={fontSize}
            lineHeight={lineHeight}
            color={checkHover(buttonColor)}
            hasLeftIcon={!!leftIcon}
            hasRightIcon={!!rightIcon}
            fontWeigh={fontWeigh()}
          >
            {loadingText}
          </BaSeTextButton>
        </>
      ) : (
        <>
          {!!leftIcon && (
            <BaSeIcon
              description={
                descriptionLeftIcon?.trim() ||
                getMessage('buttonIcon.defaultDescription')
              }
              name={leftIcon}
              size={Math.floor(fontSize * 16)}
              color={checkHover(iconColor)}
            />
          )}
          <BaSeTextButton
            fontSize={fontSize}
            lineHeight={lineHeight}
            color={checkHover(buttonColor)}
            hasLeftIcon={!!leftIcon}
            hasRightIcon={!!rightIcon}
            fontWeigh={fontWeigh()}
          >
            {value}
          </BaSeTextButton>
          {!!rightIcon && (
            <BaSeIcon
              description={
                descriptionRightIcon?.trim() ||
                getMessage('buttonIcon.defaultDescription')
              }
              name={rightIcon}
              size={Math.floor(fontSize * 16)}
              color={checkHover(iconColor)}
            />
          )}
        </>
      )}
      {objectNotEmpty(tooltip) && (
        <BaSeTooltip
          buddyRef={buttonRef}
          open={isTooltipOrPopoverOpen}
          {...(tooltip as any)}
        />
      )}
      {objectNotEmpty(popover) && (
        <BaSePopover
          buddyRef={buttonRef}
          open={isTooltipOrPopoverOpen}
          hasCloseButton={false}
          {...(popover as any)}
        />
      )}
    </Styled.Button>
  );
};

BaSeButton.displayName = 'BaSeButton';
