import styled, { keyframes } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { ProgressProps } from './progress-props';
import { numberWithUnit } from '../../../utils/string-utils';

interface ProgressBarWrappedProps extends ProgressProps {
  color?: string;
  animationDuration: number;
  animationPlayStyle: 'running' | 'paused';
}

interface ProgressBarBackgroundProps
  extends Required<Pick<ProgressProps, 'size'>> {
  color?: string;
}

const emptyingBar = keyframes`
  from {
    transform: scaleX(1)
  }
  to {
    transform: scaleX(0)
  }
`;

const fillingBar = keyframes`
  from {
    transform: scaleX(0)
  }
  to {
    transform: scaleX(1)
  }
`;

export const ProgressBarBackground = styled.div<ProgressBarBackgroundProps>`
  background: ${(p) =>
    p.color ? p.color : BaSeTheme.colors.institucionais.cinzaSebrae90};
  position: absolute;
  width: 100%;
  height: ${({ size }) => numberWithUnit(size)};
  bottom: 0;
  left: 0;
`;

export const ProgressBarWrapped = styled.div<ProgressBarWrappedProps>`
  animation: ${(p) => (p.type === 'emptying' ? emptyingBar : fillingBar)} linear
    1 forwards;
  animation-duration: ${(p) => `${p.animationDuration}ms`};
  animation-play-state: ${(p) => p.animationPlayStyle};
  background: ${(p) =>
    p.color ? p.color : BaSeTheme.colors.institucionais.cinzaSebrae60};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ size }) => numberWithUnit(size)};
  z-index: ${BaSeTheme.stackOrder.aboveNormal};
  transform-origin: left;
`;
