import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledHeading6 } from './heading6-styled';

export const BaSeHeading6: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={1}
    lineHeight={1.2}
    wrapper={StyledHeading6}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeHeading6.displayName = 'BaSeHeading6';
