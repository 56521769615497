import * as React from 'react';
import { version as BaSeVersion } from '../../package.json';
import { BaSeConfig } from '../config/config';
import { urlEncode } from '../utils/string-utils';
import {
  BaSeFonts,
  GoogleFonts,
} from '../components/typography/typography-mapper';

const ELEMENT = 'style' as const;
const TYPE = 'import-fonts' as const;

const { staticAssetsUrl: urlBaSe } = BaSeConfig;
const urlGoogle = 'https://fonts.googleapis.com/css2?display=swap';

type BaSeFontFamily = (typeof BaSeFonts)[number];
type GoogleFontFamily = (typeof GoogleFonts)[number];

const configBaSeFontFamily = (name: BaSeFontFamily) =>
  `fonts/${urlEncode(name.toLowerCase())}/web-font.css`;
const configGoogleFontFamily = (name: GoogleFontFamily) =>
  `&family=${urlEncode(
    name,
  )}:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,700`;

interface Fonts {
  BaSe: typeof BaSeFonts;
  Google: typeof GoogleFonts;
}

function notInjected(): boolean {
  return !document.querySelector(
    ELEMENT +
      `[data-type="${TYPE}"]` +
      `[data-design-system="BaSe@${BaSeVersion}"]`,
  );
}

function inject({ BaSe, Google }: Fonts): void {
  const css: string[] = [];
  BaSe.forEach((name) =>
    css.push(`@import url('${urlBaSe}/${configBaSeFontFamily(name)}')`),
  );
  const fontsGoogle = Google.map(configGoogleFontFamily).join('');
  css.push(`@import url('${urlGoogle}${fontsGoogle}');`);

  const style = document.createElement(ELEMENT);
  style.dataset.designSystem = `BaSe@${BaSeVersion}`;
  style.dataset.type = TYPE;
  style.innerText = css.join(';');
  document.head.appendChild(style);
}

function addFonts(fonts: Fonts): void {
  if (notInjected()) {
    inject(fonts);
  }
}

export function useCssFonts(): void {
  React.useEffect(() => addFonts({ BaSe: BaSeFonts, Google: GoogleFonts }), []);
}
