import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import {
  BaSeDialogModalInterface,
  BaSeDialogModalActionsInterface,
} from '../../contexts/dialog';
import { useNextCounterId } from '../../hooks/next-id';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { BaSeButton } from '../button/button/button';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeLoading } from '../loading/loading';
import { BaSeHeading4 } from '../typography/heading/heading4';
import {
  CloseButtonContainer,
  ModalBody,
  ModalContainer,
  ModalDialog,
  ModalFooter,
  ModalHeader,
} from './modal-styled';

const ModalContent: React.FC<
  Pick<BaSeDialogModalInterface, 'content' | 'onClose' | 'onContentEvent'>
> = React.memo(({ content, onClose, onContentEvent }) => (
  <>
    {content?.({
      closeModal: onClose,
      emitEvent: onContentEvent ?? (() => {}),
    }) ?? <BaSeLoading />}
  </>
));

ModalContent.displayName = 'ModalContent';

const idSequence = idGenerator();

function forcePromise<Result>(what: Promise<Result> | Result): Promise<Result> {
  if (!(what instanceof Promise)) {
    return Promise.resolve(what);
  }
  return what;
}

export const BaSeModal: React.FC<Omit<BaSeDialogModalInterface, 'id'>> = ({
  size,
  title,
  titleColor,
  leftImage,
  rightImage,
  actions = ['Fechar'],
  alignActions = 'start',
  dismissible = true,
  dismissibleIconColor = 'negative',
  dismissibleIconType = 'primary',
  dismissibleIconName = 'close-big',
  isFluid = false,
  breakpointDelimiter = 'desktopLarge',
  beforeClose,
  onClose,
  onCancel,
  onContentEvent,
  content,
}) => {
  const modalId = useNextCounterId(idSequence);

  if (leftImage || rightImage) {
    throw Error(`
      [BaSeModal] Imagens nas laterais ainda não está liberado no "showModal"!
    `);
  }

  const [dismiss, setDismiss] = React.useState<boolean>(false);
  const [actionSelected, setActionSelected] = React.useState<string>();
  const [actionLoading, setActionLoading] = React.useState<boolean[]>(
    actions.map(() => false),
  );

  React.useEffect(() => {
    if (dismiss) {
      return onCancel();
    }

    if (actionSelected) {
      return onClose(actionSelected);
    }
  }, [actionSelected, dismiss]);

  const dismissing = React.useCallback(() => setDismiss(true), [setDismiss]);

  const handleDismissibleAction = React.useCallback((event) => {
    const path = Array.from(event?.path ?? []);
    return (
      path.filter((element: HTMLElement) => {
        const classList = Array.from(element.classList ?? []);
        return classList.includes('BaSe--notification-dialog');
      }).length <= 0
    );
  }, []);

  const handleClickAction = React.useCallback(
    (actionConfig: string | BaSeDialogModalActionsInterface) => {
      let mounted = true;
      const action =
        typeof actionConfig === 'string' ? actionConfig : actionConfig.action;

      setActionLoading(
        actions.map(
          (actualActionConfig) => actionConfig === actualActionConfig,
        ),
      );

      forcePromise(beforeClose?.(action) ?? true)
        .then(
          (canClose) =>
            mounted && setActionSelected(canClose ? action : undefined),
        )
        .finally(() => mounted && setActionLoading(actions.map(() => false)));

      return () => (mounted = false);
    },
    [setActionSelected],
  );

  return (
    <ModalContainer className="BaSe--modal-container">
      <FocusTrap
        focusTrapOptions={{
          delayInitialFocus: true,
          escapeDeactivates: dismissible,
          clickOutsideDeactivates: dismissible && handleDismissibleAction,
          allowOutsideClick: true,
          onDeactivate: dismissing,
        }}
      >
        <ModalDialog
          className="BaSe--modal-dialog"
          role="dialog"
          aria-labelledby={`BaSe--modal-title-${modalId}`}
          aria-describedby={`BaSe--modal-description-${modalId}`}
          aria-modal="true"
          isFluid={!!isFluid}
          delimiter={breakpointDelimiter}
          size={size}
        >
          {title && (
            <ModalHeader className="BaSe--modal-header">
              <span id={`BaSe--modal-title-${modalId}`}>
                {typeof title === 'string' ? (
                  <BaSeHeading4
                    color={
                      titleColor ??
                      BaSeTheme.colors.institucionais.cinzaSebrae45
                    }
                    isBold={true}
                  >
                    {title}
                  </BaSeHeading4>
                ) : (
                  title()
                )}
              </span>
              {dismissible && (
                <CloseButtonContainer>
                  <BaSeShapeButton
                    buttonType="button"
                    nameIcon={dismissibleIconName}
                    shape="circle"
                    size="small"
                    sizeIcon="small"
                    type={dismissibleIconType}
                    color={dismissibleIconColor}
                    onClick={() => dismissing()}
                  />
                </CloseButtonContainer>
              )}
            </ModalHeader>
          )}
          <ModalBody id={`BaSe--modal-description-${modalId}`}>
            <ModalContent
              content={content}
              onClose={onClose}
              onContentEvent={onContentEvent}
            />

            {actions?.length > 0 && (
              <ModalFooter
                className="BaSe--modal-footer"
                alignActions={alignActions}
              >
                {actions.map((action, index) => (
                  <BaSeButton
                    key={typeof action === 'string' ? action : action.action}
                    autoFocus={index === 0}
                    buttonType="button"
                    size="medium"
                    onClick={() => handleClickAction(action)}
                    type={
                      typeof action === 'string'
                        ? `${index === 0 ? 'primary' : 'secondary'}`
                        : action?.type ??
                          `${index === 0 ? 'primary' : 'secondary'}`
                    }
                    color={
                      typeof action === 'string'
                        ? 'default'
                        : action?.color ?? 'default'
                    }
                    value={typeof action === 'string' ? action : action.action}
                    loadingText={
                      typeof action !== 'string'
                        ? action?.loadingText ?? undefined
                        : undefined
                    }
                    isLoading={actionLoading[index]}
                  />
                ))}
              </ModalFooter>
            )}
          </ModalBody>
        </ModalDialog>
      </FocusTrap>
    </ModalContainer>
  );
};

BaSeModal.displayName = 'BaSeModal';
