import en from './messages/en.json';
import pt_BR from './messages/pt-BR.json';

/**
 * @see https://www.venea.net/web/culture_code (IETF language tag - RFC 4646 standard identification for language)
 */
export type BaSeLocale =
  | 'pt-BR' // Portuguese (Brazil) -- Português (Brasil)
  // | 'ca' // Catalan -- Catalão
  // | 'cs' // Czech -- Tcheco
  // | 'de' // German -- Alemão
  | 'en'; // English -- Inglês
// | 'es' // Spanish -- Espanhol
// | 'fr' // French -- Francês
// | 'it' // Italian -- Italiano
// | 'ja' // Japanese -- Japonês
// | 'lt' // Lithuanian -- Lituano
// | 'nl' // Dutch -- Holandês
// | 'no' // Norwegian -- Norueguês
// | 'pl' // Polish -- Polonês
// | 'ru' // Russian -- Russo
// | 'sk' // Slovak -- Eslovaco
// | 'sv' // Swedish -- Sueco
// | 'tr' // Turkish -- Turco
// | 'zh-CN' // Chinese (People's Republic of China) -- Chinês (República Popular da China)

export const BaSeLocaleWithLanguages: { [key in BaSeLocale]: string } = {
  'pt-BR': 'Português (Brasil)',
  // ca: 'Català',
  // cs: 'Čeština',
  // de: 'Deutsch',
  en: 'English',
  // es: 'Español',
  // fr: 'Français',
  // it: 'Italiano',
  // ja: '日本語',
  // lt: 'Lietuvių',
  // nl: 'Nederlands',
  // no: 'Norsk',
  // pl: 'Polski',
  // ru: 'Русский',
  // sk: 'Slovenčina',
  // sv: 'Svenska',
  // tr: 'Türkçe',
  // 'zh-CN': '中文(中华人民共和国)',
};

type MessageSources = {
  [locale in BaSeLocale]: { [key: string]: string };
};

type LocalizedMessages = {
  [locale in BaSeLocale]: string;
};

interface Messages {
  [key: string]: LocalizedMessages;
}

const localizedMessages: MessageSources = {
  'pt-BR': pt_BR,
  en,
};

function createMessages(keys: MessageKey[], locales: BaSeLocale[]): Messages {
  const result: Messages = {};
  keys.forEach((key) => {
    result[key] = result?.[key] ?? {};
    locales.forEach(
      (locale) =>
        (result[key][locale] = localizedMessages?.[locale]?.[key] ?? key),
    );
  });
  return result;
}

export const messages: Messages = createMessages(
  Object.keys(pt_BR) as MessageKey[],
  ['en', 'pt-BR'],
);

// eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
function lang() {
  return pt_BR;
}

export type MessageKey = Exclude<keyof ReturnType<typeof lang>, 'z__'>;
