import { BaSeTheme } from '../../theme';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { createColor } from '../../utils/color-transformation/calculate-pattern';
import { ButtonProps } from './button-props';
import { ThemeNamedColorOrColorValue } from '../../theme/theme-interface';

const reduceLevel = (level: number) => {
  if (level > 1 && level <= 100) {
    level /= 100;
  }
  return level;
};

export const mapButtonHeight = {
  small: 32,
  medium: 40,
  big: 48,
};

// *********************
// REGULAR BUTTONS
// *********************
export const mapButtonValues = {
  primary: {
    default: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: ColorMapper.default.foreground,
        bcCHover: ColorMapper.default.hover.defaultDark,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.default.focus.defaultLight}`,
        border: 'none',
      },
    },
    destructive: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: ColorMapper.destructive.foreground,
        bcCHover: ColorMapper.destructive.hover.defaultDark,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.destructive.focus.defaultLight}`,
        border: 'none',
      },
    },
    confirmation: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: ColorMapper.confirmation.foreground,
        bcCHover: ColorMapper.confirmation.hover.defaultDark,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.confirmation.focus.defaultLight}`,
        border: 'none',
      },
    },
    disabled: {
      color: ColorMapper.disabled.foreground,
      iconColor: ColorMapper.disabled.foreground,
      style: {
        bgC: ColorMapper.disabled.background,
        bcCHover: ColorMapper.disabled.background,
        boxShadowFocus: 'none',
        border: 'none',
      },
    },
    negative: {
      color: ColorMapper.default.foreground,
      iconColor: ColorMapper.default.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: ColorMapper.default.hover.defaultLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.default.focus.defaultLight}`,
        border: 'none',
        colorHover: ColorMapper.default.hover.defaultDark,
      },
      disabled: {
        color: ColorMapper.disabled.foreground,
        iconColor: ColorMapper.disabled.foreground,
        style: {
          bgC: ColorMapper.disabled.background,
          bcCHover: ColorMapper.disabled.background,
          boxShadowFocus: 'none',
          border: 'none',
        },
      },
    },
  },
  secondary: {
    default: {
      color: ColorMapper.grayScale.foreground,
      iconColor: ColorMapper.grayScale.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: ColorMapper.grayScale.hover.defaultLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.grayScale.focus.defaultLight}`,
        border: `1px solid ${ColorMapper.grayScale.hover.defaultDark}`,
        colorHover: ColorMapper.grayScale.hover.defaultDark,
      },
    },
    destructive: {
      color: ColorMapper.destructive.foreground,
      iconColor: ColorMapper.destructive.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: ColorMapper.destructive.hover.defaultLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.destructive.focus.defaultLight}`,
        border: `1px solid ${ColorMapper.destructive.foreground}`,
        colorHover: ColorMapper.destructive.hover.defaultDark,
      },
    },
    confirmation: {
      color: ColorMapper.confirmation.foreground,
      iconColor: ColorMapper.confirmation.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: ColorMapper.confirmation.hover.defaultLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.confirmation.focus.defaultLight}`,
        border: `1px solid ${ColorMapper.confirmation.foreground}`,
        colorHover: ColorMapper.confirmation.hover.defaultDark,
      },
    },
    disabled: {
      color: ColorMapper.disabled.foreground,
      iconColor: ColorMapper.disabled.foreground,
      style: {
        bgC: ColorMapper.disabled.background,
        bcCHover: '',
        boxShadowFocus: ``,
        border: `1px solid ${ColorMapper.disabled.foreground}`,
      },
    },
    negative: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: createColor(BaSeTheme.colors.defaultColors.white, {
          opacity: reduceLevel(50),
        }),
        boxShadowFocus: `0px 0px 0px 4px ${ColorMapper.default.focus.defaultLight}`,
        border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
      },
      disabled: {
        color: ColorMapper.disabled.foreground,
        iconColor: ColorMapper.disabled.foreground,
        style: {
          bgC: ColorMapper.disabled.background,
          bcCHover: '',
          boxShadowFocus: ``,
          border: `2px solid ${ColorMapper.disabled.foreground}`,
        },
      },
    },
  },
  tertiary: {
    default: {
      color: ColorMapper.default.foreground,
      iconColor: ColorMapper.default.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: ColorMapper.default.hover.transparentLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.default.focus.transparentLight};`,
        border: `none`,
        colorHover: ColorMapper.default.hover.defaultDark,
      },
    },
    destructive: {
      color: ColorMapper.destructive.foreground,
      iconColor: ColorMapper.destructive.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: ColorMapper.destructive.hover.transparentLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.destructive.focus.transparentLight}`,
        border: `none`,
        colorHover: ColorMapper.destructive.hover.defaultDark,
      },
    },
    confirmation: {
      color: ColorMapper.confirmation.foreground,
      iconColor: ColorMapper.confirmation.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: ColorMapper.confirmation.hover.transparentLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.confirmation.focus.transparentLight}`,
        border: `none`,
        colorHover: ColorMapper.confirmation.hover.defaultDark,
      },
    },
    disabled: {
      color: ColorMapper.disabled.foreground,
      iconColor: ColorMapper.disabled.foreground,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: BaSeTheme.colors.defaultColors.transparent,
        boxShadowFocus: `none`,
        border: `none`,
      },
    },
    negative: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: createColor(BaSeTheme.colors.defaultColors.white, {
          opacity: reduceLevel(50),
        }),
        boxShadowFocus: `0px 0px 0px 4px ${ColorMapper.default.getColorByNumber(
          90,
        )}`,
        border: `none`,
      },
      disabled: {
        color: ColorMapper.disabled.getColorByNumber(60),
        iconColor: ColorMapper.disabled.getColorByNumber(60),
        style: {
          bgC: BaSeTheme.colors.defaultColors.transparent,
          bcCHover: BaSeTheme.colors.defaultColors.transparent,
          boxShadowFocus: `none`,
          border: `none`,
        },
      },
    },
  },
};

export const styleMapSize = {
  medium: {
    padding: '8.5px 12px',
    fontSize: 1,
    lineHeight: 1.4375,
    loadingSize: 16,
  },
  small: {
    padding: '8px',
    fontSize: 0.83,
    lineHeight: 1.24,
    loadingSize: 16,
  },
  big: {
    padding: '12px 16px',
    fontSize: 1.2,
    lineHeight: 1.25,
    loadingSize: 24,
  },
};

// *********************
//  SHAPE BUTTON MAPS
// *********************
export const iconButtonMapStyle = (color: string) => {
  const adaptColor = color === 'default' ? 'grayScale' : color;
  return {
    primary: {
      standard: {
        background: ColorMapper[color].foreground,
        iconColor: BaSeTheme.colors.defaultColors.white,
        hoverColor: ColorMapper[color].hover.defaultDark,
        backgroundOnFocus: ColorMapper[color].hover.defaultDark,
        borderHoverColor: '',
        iconHoverColor: '',
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper[color].focus.defaultLight}`,
        border: 'none',
        disabled: {
          background: ColorMapper.disabled.background,
          iconColor: ColorMapper.disabled.foreground,
          hoverColor: '',
          backgroundOnFocus: '',
          borderHoverColor: '',
          iconHoverColor: '',
          boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
          border: 'none',
        },
      },
      negative: {
        background: BaSeTheme.colors.defaultColors.white,
        iconColor: ColorMapper.default.foreground,
        hoverColor: ColorMapper.default.hover.defaultLight,
        backgroundOnFocus: BaSeTheme.colors.defaultColors.white,
        borderHoverColor: '',
        iconHoverColor: '',
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.default.focus.defaultLight}`,
        border: 'none',
        disabled: {
          background: ColorMapper.disabled.background,
          iconColor: ColorMapper.disabled.foreground,
          hoverColor: '',
          backgroundOnFocus: '',
          borderHoverColor: '',
          iconHoverColor: '',
          boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
          border: 'none',
        },
      },
    },
    secondary: {
      standard: {
        background: BaSeTheme.colors.defaultColors.white,
        iconColor: ColorMapper[adaptColor].foreground,
        hoverColor: ColorMapper[adaptColor].hover.defaultLight,
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper[adaptColor].focus.defaultLight}`,
        border: `1px solid ${ColorMapper[adaptColor].foreground}`,
        backgroundOnFocus: ColorMapper[adaptColor].hover.defaultLight,
        borderHoverColor: `1px solid ${ColorMapper[adaptColor].hover.defaultDark}`,
        iconHoverColor: ColorMapper[adaptColor].hover.defaultDark,
        disabled: {
          background: ColorMapper.disabled.background,
          iconColor: ColorMapper.disabled.foreground,
          hoverColor: 'none',
          boxShadowFocus: 'none',
          backgroundOnFocus: '',
          border: `1px solid ${ColorMapper.disabled.foreground}`,
          borderHoverColor: `1px solid ${ColorMapper.grayScale.hover.defaultDark}`,
          iconHoverColor: ColorMapper.grayScale.hover.defaultDark,
        },
      },
      negative: {
        background: BaSeTheme.colors.defaultColors.transparent,
        iconColor: BaSeTheme.colors.defaultColors.white,
        hoverColor: createColor(BaSeTheme.colors.defaultColors.white, {
          opacity: reduceLevel(50),
        }),
        border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
        borderColor: ColorMapper.default.foreground,
        backgroundOnFocus: BaSeTheme.colors.defaultColors.transparent,
        borderHoverColor: '',
        iconHoverColor: '',
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper.default.focus.defaultLight}`,
        disabled: {
          background: ColorMapper.disabled.background,
          iconColor: ColorMapper.disabled.foreground,
          hoverColor: 'none',
          boxShadowFocus: 'none',
          backgroundOnFocus: '',
          border: `2px solid ${ColorMapper.disabled.foreground}`,
          borderHoverColor: `1px solid ${ColorMapper.grayScale.hover.defaultDark}`,
          iconHoverColor: ColorMapper.grayScale.hover.defaultDark,
        },
      },
    },
    tertiary: {
      standard: {
        background: BaSeTheme.colors.defaultColors.transparent,
        iconColor: ColorMapper[color].foreground,
        hoverColor: ColorMapper[color].hover.transparentLight,
        backgroundOnFocus: ColorMapper[color].hover.transparentLight,
        borderHoverColor: '',
        iconHoverColor: '',
        boxShadowFocus: `0px 0px 0px 3px ${ColorMapper[color].focus.defaultLight}`,
        border: 'none',
        disabled: {
          background: ColorMapper.disabled.background,
          iconColor: ColorMapper.disabled.foreground,
          hoverColor: 'none',
          boxShadowFocus: 'none',
          backgroundOnFocus: '',
          borderHoverColor: '',
          iconHoverColor: '',
          border: 'none',
        },
      },
      negative: {
        background: BaSeTheme.colors.defaultColors.transparent,
        iconColor: BaSeTheme.colors.defaultColors.white,
        hoverColor: createColor(BaSeTheme.colors.defaultColors.white, {
          opacity: reduceLevel(50),
        }),
        backgroundOnFocus: BaSeTheme.colors.defaultColors.transparent,
        borderHoverColor: '',
        iconHoverColor: '',
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.defaultColors.white}`,
        border: 'none',
        disabled: {
          background: BaSeTheme.colors.defaultColors.transparent,
          iconColor: ColorMapper.disabled.foreground,
          hoverColor: 'none',
          backgroundOnFocus: '',
          borderHoverColor: '',
          iconHoverColor: '',
          boxShadowFocus: '',
          border: 'none',
        },
      },
    },
  };
};

export const iconButtonIconMapSize = {
  small: {
    iconSize: 16,
  },
  medium: {
    iconSize: 24,
  },
  big: {
    iconSize: 32,
  },
};

export const iconButtonBorderRadius = {
  circle: '50%',
  square: '4px;',
};

// *********************
//  DUAL BUTTON MAPS
// *********************
export const dualButtonMapStyle = {
  primary: {
    default: {
      background: BaSeTheme.colors.defaultColors.white,
      color: ColorMapper.default.foreground,
      hoverColor: ColorMapper.default.hover.defaultLight,
      boxShadowFocus: `0 0 0 4px ${ColorMapper.default.focus.defaultLight}`,
      border: '1px 1px 1px 1px',
      borderColor: ColorMapper.default.focus.defaultLight,
      borderRadius: '4',
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: createColor(BaSeTheme.colors.defaultColors.white, {
        opacity: reduceLevel(50),
      }),
      boxShadowFocus: `0 0 0 4px ${ColorMapper.default.focus.defaultLight}`,
      border: '1px 1px 1px 0px',
      borderColor: ColorMapper.grayScale.focus.defaultLight,
      borderRadius: '4',
    },
    error: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: ColorMapper.destructive.hover.defaultDark,
      hoverColor: '',
      boxShadowFocus: '0px 0px 0px 0px',
      border: '1px 1px 1px 0px',
      borderColor: ColorMapper.destructive.hover.defaultDark,
      borderRadius: '4',
    },
  },
  selected: {
    default: {
      background: ColorMapper.default.foreground,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: '',
      boxShadowFocus: `0 0 0 4px ${ColorMapper.default.focus.defaultLight}`,
      border: '1px 1px 1px 0px',
      borderColor: ColorMapper.default.foreground,
      borderRadius: '4',
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.white,
      color: ColorMapper.default.foreground,
      hoverColor: '',
      boxShadowFocus: `0 0 0 4px ${ColorMapper.default.focus.defaultLight}`,
      border: '1px 1px 1px 0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '4',
    },
    error: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: ColorMapper.destructive.hover.defaultDark,
      hoverColor: '',
      boxShadowFocus: '0px 0px 0px 0px',
      border: '1px 1px 1px 0px',
      borderColor: ColorMapper.destructive.hover.defaultDark,
      borderRadius: '4',
    },
  },
};

// *********************
//  ARROW BUTTON MAPS
// *********************
export const arrowButtonMapStyle = {
  primary: {
    default: {
      background: ColorMapper.default.foreground,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: ColorMapper.default.hover.defaultDark,
      boxShadowFocus: ColorMapper.default.focus.defaultLight,
      border: '0px',
      borderColor: ColorMapper.default.foreground,
      arrows: {
        top: '4.75px',
      },
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.white,
      color: ColorMapper.default.foreground,
      hoverColor: ColorMapper.default.hover.transparentLight,
      boxShadowFocus: ColorMapper.default.focus.transparentLight,
      border: '0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      textHoverColor: ColorMapper.default.hover.defaultDark,
      borderHoverColor: ColorMapper.default.hover.defaultDark,
      arrows: {
        top: '4.75px',
      },
    },
    disabled: {
      background: ColorMapper.disabled.background,
      color: ColorMapper.disabled.foreground,
      hoverColor: '',
      boxShadowFocus: '',
      border: '0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      arrows: {
        top: '4.75px',
      },
      negative: {
        background: BaSeTheme.colors.defaultColors.white,
        color: ColorMapper.default.foreground,
        hoverColor: ColorMapper.default.hover.transparentLight,
        boxShadowFocus: ColorMapper.default.focus.defaultLight,
        border: '0px',
        borderColor: BaSeTheme.colors.defaultColors.white,
        textHoverColor: ColorMapper.default.hover.defaultDark,
        borderHoverColor: ColorMapper.default.hover.defaultDark,
        arrows: {
          top: '4.75px',
        },
      },
    },
  },
  secondary: {
    default: {
      background: BaSeTheme.colors.defaultColors.white,
      color: ColorMapper.grayScale.foreground,
      hoverColor: ColorMapper.grayScale.hover.defaultLight,
      boxShadowFocus: ColorMapper.disabled.foreground,
      border: '1px',
      borderColor: ColorMapper.grayScale.foreground,
      textHoverColor: ColorMapper.grayScale.hover.defaultDark,
      borderHoverColor: ColorMapper.grayScale.hover.defaultDark,
      arrows: {
        top: '3.75px',
      },
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: createColor(BaSeTheme.colors.defaultColors.white, {
        opacity: reduceLevel(50),
      }),
      boxShadowFocus: `${ColorMapper.default.focus.defaultLight}`,
      border: '2px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      arrows: {
        top: '2.75px',
      },
    },
    disabled: {
      background: ColorMapper.disabled.background,
      color: ColorMapper.disabled.foreground,
      hoverColor: '',
      boxShadowFocus: '',
      border: '1px',
      borderColor: `${ColorMapper.disabled.foreground}`,
      negative: {
        background: ColorMapper.disabled.background,
        color: ColorMapper.disabled.foreground,
        hoverColor: '',
        boxShadowFocus: '',
        border: '2px',
        borderColor: `${ColorMapper.disabled.foreground}`,
        arrows: {
          top: '2.75px',
        },
      },
      arrows: {
        top: '3.5px',
      },
    },
  },
};

// *********************
//  CUSTOM COLORS BUTTON MAPS
// *********************
export const mapCustomColorsButtonValues = (
  customColor: ThemeNamedColorOrColorValue,
  ignoreTextHover: boolean = false,
  overrideColors?: ButtonProps['overrideColors'],
) => {
  const {
    darkHoverColor,
    lightFocusColor,
    lightHoverColor,
    lightFocusColorTransparent,
    lightHoverColorTransparent,
  } = ColorMapper.createMostUsedColors(customColor as string);

  const matchColorToReplace = (matchColor: string, defaultColor: string) => {
    return ignoreTextHover ? defaultColor : matchColor;
  };

  return {
    primary: {
      color: ColorMapper?.getColor(
        overrideColors?.foreground,
        BaSeTheme.colors.defaultColors.white,
      ),
      iconColor: ColorMapper?.getColor(
        overrideColors?.foreground,
        BaSeTheme.colors.defaultColors.white,
      ),
      style: {
        bgC: ColorMapper?.getColor(
          overrideColors?.backgroundColor,
          (customColor as string) ?? ColorMapper.default.foreground,
        ),
        bcCHover: overrideColors?.backgroundColor
          ? ColorMapper.dark.hover.create(
              ColorMapper?.getColor(overrideColors?.backgroundColor),
            )
          : darkHoverColor,
        boxShadowFocus: `0px 0px 0px 3px ${
          overrideColors?.backgroundColor
            ? ColorMapper?.light.focus.create(
                ColorMapper?.getColor(overrideColors?.backgroundColor),
              )
            : lightFocusColor
        }`,
        border: 'none',
      },
    },
    secondary: {
      color: ColorMapper?.getColor(
        overrideColors?.foreground,
        (customColor as string) ?? ColorMapper.grayScale.foreground,
      ),
      iconColor: ColorMapper?.getColor(
        overrideColors?.foreground,
        (customColor as string) ?? ColorMapper.grayScale.foreground,
      ),
      style: {
        bgC: ColorMapper?.getColor(
          overrideColors?.backgroundColor,
          BaSeTheme.colors.defaultColors.white,
        ),
        bcCHover: overrideColors?.backgroundColor
          ? ColorMapper.light.hover.create(
              ColorMapper?.getColor(overrideColors?.backgroundColor),
            )
          : lightHoverColor,
        boxShadowFocus: `0px 0px 0px 3px ${
          overrideColors?.backgroundColor
            ? ColorMapper?.light.focus.create(
                ColorMapper?.getColor(overrideColors?.backgroundColor),
              )
            : lightFocusColor
        }`,
        border: overrideColors?.borderColor
          ? `1px solid ${ColorMapper?.getColor(overrideColors?.borderColor)}`
          : `1px solid ${customColor as string}`,
        colorHover: overrideColors?.foreground
          ? matchColorToReplace(
              ColorMapper?.dark?.hover.create(
                ColorMapper?.getColor(overrideColors?.foreground),
              ),
              overrideColors?.foreground,
            )
          : matchColorToReplace(
              darkHoverColor,
              ColorMapper?.getColor(
                overrideColors?.foreground,
                (customColor as string) ?? ColorMapper.grayScale.foreground,
              ),
            ),
      },
    },
    tertiary: {
      color: ColorMapper?.getColor(
        overrideColors?.foreground,
        (customColor as string) ?? ColorMapper.grayScale.foreground,
      ),
      iconColor: ColorMapper?.getColor(
        overrideColors?.foreground,
        (customColor as string) ?? ColorMapper.grayScale.foreground,
      ),
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: overrideColors?.foreground
          ? ColorMapper.light.hover.createTransparent(
              ColorMapper?.getColor(overrideColors?.foreground),
            )
          : lightHoverColorTransparent,
        boxShadowFocus: `0px 0px 0px 3px ${
          overrideColors?.foreground
            ? ColorMapper.light.focus.createTransparent(
                ColorMapper?.getColor(overrideColors?.foreground),
              )
            : lightFocusColorTransparent
        }`,
        border: `none`,
        colorHover: overrideColors?.foreground
          ? matchColorToReplace(
              ColorMapper?.dark?.hover.create(
                ColorMapper?.getColor(overrideColors?.foreground),
              ),
              overrideColors?.foreground,
            )
          : matchColorToReplace(
              darkHoverColor,
              ColorMapper?.getColor(
                overrideColors?.foreground,
                (customColor as string) ?? ColorMapper.grayScale.foreground,
              ),
            ),
      },
    },
  };
};

// *********************
//  CUSTOM COLORS ARROW BUTTON MAPS
// *********************
export const mapCustomColorsArrowButtonValues = (customColor: string) => {
  const { darkHoverColor, lightFocusColor, lightHoverColor } =
    ColorMapper.createMostUsedColors(customColor);

  return {
    primary: {
      background: customColor,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: darkHoverColor,
      boxShadowFocus: lightFocusColor,
      border: '0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '7.5',
    },
    secondary: {
      background: BaSeTheme.colors.defaultColors.white,
      color: customColor,
      hoverColor: lightHoverColor,
      boxShadowFocus: lightFocusColor,
      border: '1px',
      borderColor: customColor,
      borderRadius: '7.5',
    },
  };
};

// *********************
//  CUSTOM COLORS ARROW BUTTON MAPS
// *********************
export const mapCustomColorsIconButtonValues = (customColor: string) => {
  const {
    darkHoverColor,
    lightFocusColor,
    lightHoverColor,
    lightFocusColorTransparent,
    lightHoverColorTransparent,
  } = ColorMapper.createMostUsedColors(customColor);

  return {
    primary: {
      background: customColor,
      iconColor: BaSeTheme.colors.defaultColors.white,
      hoverColor: darkHoverColor,
      boxShadowFocus: `0px 0px 0px 3px ${lightFocusColor}`,
      border: 'none',
      backgroundOnFocus: '',
      borderHoverColor: '',
      iconHoverColor: '',
    },
    secondary: {
      background: BaSeTheme.colors.defaultColors.white,
      iconColor: customColor,
      hoverColor: lightHoverColor,
      boxShadowFocus: `0px 0px 0px 3px ${lightFocusColor}`,
      border: `1px solid ${customColor}`,
      backgroundOnFocus: '',
      borderHoverColor: '',
      iconHoverColor: '',
    },
    tertiary: {
      background: BaSeTheme.colors.defaultColors.transparent,
      iconColor: customColor,
      hoverColor: lightHoverColorTransparent,
      boxShadowFocus: `0px 0px 0px 3px ${lightFocusColorTransparent}`,
      border: 'none',
      backgroundOnFocus: '',
      borderHoverColor: '',
      iconHoverColor: '',
    },
  };
};

// Overrider

export const buttonOverrideColor = (
  overrider: ButtonProps['overrideColors'],
) => {
  return {
    primary: {
      color: ColorMapper?.getColor(overrider?.foreground),
      iconColor: ColorMapper?.getColor(overrider?.foreground),
      style: {
        bgC: ColorMapper?.getColor(overrider?.backgroundColor),
        bcCHover:
          overrider?.backgroundColor &&
          ColorMapper.dark.hover.create(
            ColorMapper?.getColor(overrider?.backgroundColor),
          ),
        boxShadowFocus:
          overrider?.backgroundColor &&
          `0px 0px 0px 3px ${ColorMapper.light.focus.create(
            ColorMapper?.getColor(overrider?.backgroundColor),
          )}`,
      },
    },
    secondary: {
      color: ColorMapper?.getColor(overrider?.foreground),
      iconColor: ColorMapper?.getColor(overrider?.foreground),
      style: {
        bgC: ColorMapper?.getColor(overrider?.backgroundColor),
        bcCHover:
          (overrider?.foreground || overrider?.backgroundColor) &&
          ColorMapper?.light.hover.create(
            ColorMapper?.getColor(
              overrider?.backgroundColor || overrider?.foreground,
            ),
          ),
        boxShadowFocus:
          (overrider?.backgroundColor || overrider?.foreground) &&
          `0px 0px 0px 3px ${ColorMapper.light.focus.create(
            ColorMapper?.getColor(
              overrider?.backgroundColor || overrider?.foreground,
            ),
          )}`,
        border:
          overrider?.borderColor &&
          `1px solid ${ColorMapper?.getColor(overrider?.borderColor)}`,
        colorHover:
          overrider?.foreground &&
          ColorMapper.dark.hover.create(
            ColorMapper.getColor(overrider?.foreground),
          ),
      },
    },
    tertiary: {
      color: ColorMapper?.getColor(overrider?.foreground),
      iconColor: ColorMapper?.getColor(overrider?.foreground),
      style: {
        bcCHover:
          overrider?.foreground &&
          ColorMapper?.light.hover.createTransparent(
            ColorMapper?.getColor(overrider?.foreground),
          ),
        boxShadowFocus:
          overrider?.foreground &&
          `0px 0px 0px 3px ${ColorMapper.light.focus.createTransparent(
            ColorMapper?.getColor(overrider?.foreground),
          )}`,
        border: 'none',
        colorHover:
          overrider?.foreground &&
          ColorMapper.dark.hover.create(
            ColorMapper.getColor(overrider?.foreground),
          ),
      },
    },
  };
};

export const iconButtonOverrideColor = (
  overrider: ButtonProps['overrideColors'],
) => {
  return {
    primary: {
      background: ColorMapper?.getColor(overrider?.backgroundColor),
      iconColor: ColorMapper?.getColor(overrider?.foreground),
      hoverColor:
        overrider?.backgroundColor &&
        ColorMapper.dark.hover.create(
          ColorMapper?.getColor(overrider?.backgroundColor),
        ),
      boxShadowFocus:
        overrider?.backgroundColor &&
        `0px 0px 0px 3px ${ColorMapper.light.focus.create(
          ColorMapper?.getColor(overrider?.backgroundColor),
        )}`,
    },
    secondary: {
      iconColor: ColorMapper?.getColor(overrider?.foreground),
      background: ColorMapper?.getColor(overrider?.backgroundColor),
      hoverColor:
        overrider?.foreground &&
        ColorMapper?.light.hover.create(
          ColorMapper?.getColor(overrider?.foreground),
        ),
      boxShadowFocus:
        overrider?.foreground &&
        `0px 0px 0px 3px ${ColorMapper.light.focus.create(
          ColorMapper?.getColor(overrider?.foreground),
        )}`,
      border:
        overrider?.borderColor &&
        `1px solid ${ColorMapper?.getColor(overrider?.borderColor)}`,
    },
    tertiary: {
      background: ColorMapper?.getColor(overrider?.backgroundColor),
      iconColor: ColorMapper?.getColor(overrider?.foreground),
      hoverColor:
        overrider?.foreground &&
        ColorMapper.light.hover.createTransparent(
          ColorMapper.getColor(overrider?.foreground),
        ),
      boxShadowFocus:
        overrider?.foreground &&
        `0px 0px 0px 3px ${ColorMapper.light.focus.createTransparent(
          ColorMapper?.getColor(overrider?.foreground),
        )}`,
    },
  };
};
