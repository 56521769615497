import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { numberWithUnit } from '../../../utils/string-utils';

interface IconStyleProps {
  disabled: boolean;
  type: string;
  border: string;
  borderRadius: string;
  backgroundColor: string;
  backgroundOnHover: string;
  boxShadowOnFocus: string;
  padding: 'none' | number;
  sideButton?: 'left' | 'right' | 'none';
  backgroundOnFocus: string;
  width: number;
}

export const IconButton = styled.button.attrs({
  className: 'BaSe--icon-button',
})<IconStyleProps>`
  position: relative;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: ${({ borderRadius, sideButton }) =>
    sideButton === 'none'
      ? borderRadius
      : sideButton === 'left'
        ? '4px 0px 0px 4px'
        : '0px 4px 4px 0px'};
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};

  ${({ width: size, padding }) => css`
    padding: ${numberWithUnit(padding)};
    min-width: calc(${size}em - (${numberWithUnit(padding)} * 2));
    min-height: calc(${size}em - (${numberWithUnit(padding)} * 2));
    width: calc(${size}em - (${numberWithUnit(padding)} * 2));
    height: calc(${size}em - (${numberWithUnit(padding)} * 2));
    max-width: calc(${size}em - (${numberWithUnit(padding)} * 2));
    max-height: calc(${size}em - (${numberWithUnit(padding)} * 2));
  `}

  &:hover {
    background-color: ${({ backgroundOnHover }) => backgroundOnHover};
  }

  &:focus {
    border-radius: ${({ borderRadius, sideButton }) =>
      sideButton === 'none'
        ? borderRadius
        : sideButton === 'left'
          ? '4px 0px 0px 4px'
          : '0px 4px 4px 0px'};
    background-color: ${({ backgroundOnFocus }) => backgroundOnFocus};
    box-shadow: ${({ boxShadowOnFocus }) => boxShadowOnFocus};
    outline: none;
    border-color: ${BaSeTheme.colors.defaultColors.transparent};
  }

  &:active:not(:disabled) {
    box-shadow: none;
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
