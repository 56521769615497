import * as React from 'react';

import { TextButton } from './text-button-styled';

interface TextButtonInterface {
  color: string;
  fontSize: number;
  lineHeight: number;
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
  fontWeigh: string;
}

export const BaSeTextButton: React.FC<TextButtonInterface> = ({
  color = '#ffffff',
  fontSize = 1,
  lineHeight = 1.2,
  hasLeftIcon = false,
  hasRightIcon = false,
  fontWeigh = 'normal',
  children = '',
}) => (
  <TextButton
    color={color}
    fontSize={fontSize}
    lineHeight={lineHeight}
    hasLeftIcon={hasLeftIcon}
    hasRightIcon={hasRightIcon}
    fontWeigh={fontWeigh}
  >
    {children}
  </TextButton>
);

BaSeTextButton.displayName = 'BaSeTextButton';
