import { Color } from '../../utils/color-transformation/color';
import { BaSeTheme } from '../../theme/index';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { createColorSmartLightingControl } from '../../utils/color-transformation/calculate-pattern';

const inst = BaSeTheme.colors.institucionais;
const gest = BaSeTheme.colors.gestao;
const def = BaSeTheme.colors.defaultColors;
const feed = BaSeTheme.colors.feedbackInterface;

export const TypographyLinkMapper = {
  default: {
    foreground: ColorMapper.default.foreground,
    hover: ColorMapper.default.unchecked,
    active: ColorMapper.default.hover.defaultDark,
    focus: ColorMapper.default.unchecked,
    focusShadow: ColorMapper.default.focus.defaultLight,
    visited: gest.roxo44,
    disabled: ColorMapper.disabled.foreground,
  },
  destructive: {
    foreground: ColorMapper.destructive.foreground,
    hover: ColorMapper.destructive.unchecked,
    active: ColorMapper.destructive.hover.defaultDark,
    focus: ColorMapper.destructive.unchecked,
    focusShadow: ColorMapper.destructive.focus.defaultLight,
    visited: gest.roxo44,
    disabled: ColorMapper.disabled.foreground,
  },
  confirmation: {
    foreground: ColorMapper.confirmation.foreground,
    hover: ColorMapper.confirmation.unchecked,
    active: ColorMapper.confirmation.hover.defaultDark,
    focus: ColorMapper.confirmation.unchecked,
    focusShadow: ColorMapper.confirmation.focus.defaultLight,
    visited: gest.roxo44,
    disabled: ColorMapper.disabled.foreground,
  },
  negative: {
    foreground: inst.azulSebrae90,
    hover: def.white,
    active: ColorMapper.default.focus.defaultLight,
    focus: def.white,
    focusShadow: inst.azulSebrae90,
    visited: gest.roxo75,
    disabled: ColorMapper.grayScale.foreground,
  },
};

export const TypographyCustomLinkMapper = (color: string) => {
  if (!Color.validateColor(color)) {
    return undefined;
  }
  return {
    foreground: color,
    hover: createColorSmartLightingControl(color, { l: 0.6 }, 'lighter'),
    active: ColorMapper.dark.hover.create(color),
    focus: createColorSmartLightingControl(color, { l: 0.6 }, 'lighter'),
    focusShadow: ColorMapper.light.focus.create(color),
    visited: gest.roxo44,
    disabled: ColorMapper.disabled.foreground,
  };
};

export const TypographyTypesColor = {
  default: {
    foreground: inst.cinzaSebrae45,
    strong: inst.cinzaSebrae30,
    soft: inst.cinzaSebrae45,
    unselected: inst.cinzaSebrae60,
    selected: inst.cinzaSebrae20,
  },
  confirmation: {
    foreground: feed.sucesso45,
    strong: feed.sucesso26,
    soft: feed.sucesso45,
    unselected: feed.sucesso70,
    selected: feed.sucesso20,
  },
  destructive: {
    foreground: feed.erro45,
    strong: feed.erro35,
    soft: feed.erro45,
    unselected: feed.erro60,
    selected: createColorSmartLightingControl(
      feed.erro45,
      { l: 0.2 },
      'darker',
    ),
  },
};

export const TypographyCustomTypesColor = (color?: string) => {
  if (!color) {
    return undefined;
  }
  return {
    foreground: color,
    strong: createColorSmartLightingControl(color, { l: 0.3 }, 'darker'),
    soft: color,
    unselected: createColorSmartLightingControl(color, { l: 0.6 }, 'lighter'),
    selected: createColorSmartLightingControl(color, { l: 0.2 }, 'darker'),
  };
};

export const BaSeFonts = ['Campuni'] as const;
export const GoogleFonts = [
  'Lato',
  'Alegreya Sans',
  'Open Sans',
  'Nunito',
  'Nunito Sans',
] as const;

const DefaultFontWeight = {
  light: 300,
  regular: 400,
  bold: 700,
};

const CustomFontWeightMapper = {
  Campuni: { ...DefaultFontWeight, regular: 500 },
};

export const getFontWeight = (
  font?: (typeof BaSeFonts)[number] | (typeof GoogleFonts)[number],
) =>
  (CustomFontWeightMapper[font ?? ''] ??
    DefaultFontWeight) as typeof DefaultFontWeight;
