import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledSmall1 } from './small1-styled';

export const BaSeSmall1: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={0.83}
    lineHeight={1.2}
    wrapper={StyledSmall1}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeSmall1.displayName = 'BaSeSmall1';
