import * as React from 'react';
import { getNextCounter, idGenerator } from '../../utils/id-generator';
import { BaSePopupTutorial, PopupTutorialProps } from './tutorial-popup';
import { TutorialContainer } from './tutorial-styled';

export interface TutorialStepsProps
  extends Pick<PopupTutorialProps, 'content' | 'title' | 'anchor'> {}

export interface BaSeDialogTutorialPollInterface {
  steps: TutorialStepsProps[];
  onClose: () => void;
  dismissible?: boolean;
}

const idSequence = idGenerator();

export const BaSeDialogTutorial: React.FC<BaSeDialogTutorialPollInterface> = ({
  onClose,
  steps,
  dismissible = true,
}) => {
  const [activeStep, setActiveStep] = React.useState(1);
  const totalSteps = React.useMemo(() => steps.length, [steps]);

  const handleClick = React.useCallback((e) => {
    if (
      e?.target?.className?.includes('BaSe--tutorial-container') &&
      dismissible
    ) {
      onClose();
    }
  }, []);

  const mapPropsToPopup = React.useCallback(
    (step: TutorialStepsProps, index: number) => {
      const idTip = getNextCounter(idSequence);
      return (
        <BaSePopupTutorial
          dismissible={dismissible}
          onClose={onClose}
          open={activeStep === index + 1}
          anchor={step.anchor}
          currentStep={index + 1}
          totalSteps={totalSteps}
          title={step.title}
          content={step.content}
          previousStep={() => setActiveStep((prev) => prev - 1)}
          nextStep={() => setActiveStep((prev) => prev + 1)}
          key={idTip}
        />
      );
    },
    [totalSteps, activeStep],
  );

  const showListTips = React.useMemo(
    () => steps.map(mapPropsToPopup),
    [steps, activeStep],
  );

  React.useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'ArrowLeft') {
        setActiveStep((prev) => {
          const p = prev - 1;
          if (p <= 0) {
            return prev;
          }
          return p;
        });
        return;
      }
      if (event.key === 'ArrowRight') {
        setActiveStep((prev) => {
          const p = prev + 1;
          if (p > totalSteps) {
            return prev;
          }
          return p;
        });
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [totalSteps]);

  if (!steps?.length) {
    return <React.Fragment />;
  }

  return (
    <TutorialContainer
      onClick={handleClick}
      className="BaSe--tutorial-container"
    >
      {showListTips}
    </TutorialContainer>
  );
};
