import styled from 'styled-components';
import { BaSeTheme } from '../../../theme/index';
import { transformLightness } from '../../../utils/color-transformation/transform-lightness';
import {
  AlertStyledProps,
  AlertThemeProps,
  AlertTriangleProps,
} from '../alerts-props';

export const PopoverContainer = styled.div.attrs({
  className: 'BaSe--popover',
})<AlertStyledProps>`
  ${(props) => (props.open ? 'visibility: visible;' : 'visibility: hidden;')}
  ${(props) => !props.open && 'pointer-events: none;'}
  opacity: ${(props) => (props.open ? 1 : 0)};
  position: absolute;
  ${(props) => props.top && 'top:' + props.top + ';'}
  ${(props) => props.right && 'right:' + props.right + ';'}
  ${(props) => props.bottom && 'bottom:' + props.bottom + ';'}
  ${(props) => props.left && 'left:' + props.left + ';'}
  min-width: ${(props) => props.width}px;
  z-index: ${BaSeTheme.stackOrder.aboveNormal};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  will-change: opacity;
`;

export const PopoverCard = styled.div.attrs({
  className: 'BaSe--popover-card',
})<AlertThemeProps>`
  border-radius: 4px;
  padding: 12px 24px 16px 16px;
  position: relative;
  background-color: ${({ color, themeMode, overrideColors }) =>
    overrideColors?.backgroundColor ||
    (themeMode === 'light'
      ? BaSeTheme.colors.defaultColors.white
      : transformLightness(color as string, 0.15))};
  border: 1px solid
    ${({ color, themeMode, overrideColors }) =>
      overrideColors?.borderColor ||
      (themeMode === 'light'
        ? transformLightness(color as string, 0.9)
        : transformLightness(color as string, 0.15))};
  box-shadow: ${BaSeTheme.shadows.profundidade4};
`;

export const PopoverCardTitleContainer = styled.div<AlertThemeProps>`
  padding-bottom: 1px;
  align-items: center;
  > small {
    font-weight: bold;
    color: ${({ color, themeMode, overrideColors }) =>
      overrideColors?.titleColor ||
      (themeMode === 'light'
        ? color
        : transformLightness(color as string, 0.9))};
  }
`;

export const PopoverCardBodyContainer = styled.div<AlertThemeProps>`
  > small {
    color: ${({ themeMode, overrideColors }) =>
      overrideColors?.foreground ||
      (themeMode === 'light'
        ? BaSeTheme.colors.institucionais.cinzaSebrae45
        : BaSeTheme.colors.defaultColors.white)};
  }
`;

export const PopoverCardContentContainer = styled.div`
  &:not(:empty) {
    margin-top: 8px;
  }
`;

export const PopoverButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  > button {
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
    line-height: ${BaSeTheme.typography.lineHeightBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 20px;
    height: 20px;
    > img {
      width: 12px;
      height: 12px;
    }
  }
`;

export const PopoverTriangle = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.stackOrder.aboveNormal + 1};
`;

export const PopoverTriangleSmaller = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.stackOrder.aboveNormal + 2};
`;
