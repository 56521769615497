import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { firstLetterUpperCase } from '../../utils/string-utils';

interface StaticAssetStyledProps {
  width?: string;
  height?: string;
  filter?: string;
  colorTransition?: 'slow' | 'normal' | 'fast';
}

export const StaticAssetStyled = styled.img<StaticAssetStyledProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  filter: ${({ filter }) => filter};
  opacity: ${({ src }) => (src ? 1 : 0)};
  transition: opacity ${`
    ${BaSeTheme.transitions.durationFast}
    ${BaSeTheme.transitions.continuousTimingFunction}
  `};

  ${({ colorTransition }) =>
    colorTransition &&
    css`
      transition:
        opacity ${`
        ${
          BaSeTheme.transitions[
            `duration${firstLetterUpperCase(colorTransition)}`
          ]
        }
        ${BaSeTheme.transitions.continuousTimingFunction}
      `},
        filter ${`
        ${
          BaSeTheme.transitions[
            `duration${firstLetterUpperCase(colorTransition)}`
          ]
        }
        ${BaSeTheme.transitions.continuousTimingFunction}
      `};
    `}

  &::selection {
    background: transparent;
  }

  &:focus {
    outline: none;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      // TODO ver qual vai ser o efeito de focus e hover
      // &:focus {
      //   ...
      // }
      // &:hover {
      //   ...
      // }
    `}
`;
