import { BaSeTheme } from '../../theme';
import { Color } from '../../utils/color-transformation/color';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';

export const mapNotificationValues = (color: string) => {
  return {
    primary: {
      generalColors: {
        color: ColorMapper[color].hover.defaultDark as string,
        style: {
          bgC: ColorMapper[color].hover.defaultLight as string,
        },
      },
    },
  };
};

export const mapCustomColorsNotificationValues = (customColor: string) => {
  const customColorHsv = Color.generateFromHex(customColor);

  return {
    primary: {
      color: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC:
          customColor && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
      },
    },
  };
};
