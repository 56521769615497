import * as React from 'react';
import ReactQRCode from 'react-qr-code';
import styled, { css, keyframes } from 'styled-components';
import { BaSeTheme } from '../theme';
import { ThemeColorValue } from '../theme/theme-interface';
import { BaSeTransitions } from '../theme/transitions';
import { numberWithUnit, removeAccents } from '../utils/string-utils';

const ERROR_CORRECTION_LEVELS = {
  low: 'L',
  medium: 'M',
  quartile: 'Q',
  high: 'H',
} as const;

export interface QRCodeOptions {
  content: string;
  id: string;
  description: string;
  size?: number;
  logo?: JSX.Element;
  logoPadding?: number;
  foregroundColor?: ThemeColorValue;
  backgroundColor?: ThemeColorValue;
  errorCorrectionLevel?: keyof typeof ERROR_CORRECTION_LEVELS;
}

export interface HookQRCode {
  generate: (options: QRCodeOptions) => Promise<JSX.Element>;
}

const fadeIn = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

const QRCodeWrapper = styled.div<
  Pick<QRCodeOptions, 'size' | 'backgroundColor' | 'logoPadding'>
>`
  animation: ${fadeIn} ${BaSeTransitions.durationSlow};
  position: relative;
  ${({ size }) => css`
    width: ${numberWithUnit(size)};
    height: ${numberWithUnit(size)};
  `}
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ backgroundColor, logoPadding }) => css`
      background: ${backgroundColor};
      padding: ${numberWithUnit(logoPadding)};
    `}
  }
`;

export function useQRCode(): HookQRCode {
  const generate = React.useCallback(
    ({
      content,
      id,
      description,
      logo,
      size = 256,
      logoPadding = 8,
      foregroundColor = BaSeTheme.colors.defaultColors.black,
      backgroundColor = BaSeTheme.colors.defaultColors.white,
      errorCorrectionLevel = 'medium',
    }: QRCodeOptions) => {
      const qrCode = (
        <ReactQRCode
          id={id}
          value={removeAccents(content)}
          size={size}
          fgColor={foregroundColor}
          bgColor={backgroundColor}
          level={ERROR_CORRECTION_LEVELS[errorCorrectionLevel]}
          aria-label={description}
        />
      );
      return new Promise<JSX.Element>((resolve) => {
        resolve(
          <QRCodeWrapper
            size={size}
            backgroundColor={backgroundColor}
            logoPadding={logoPadding}
          >
            {qrCode}
            {logo && <span>{logo}</span>}
          </QRCodeWrapper>,
        );
      });
    },
    [],
  );

  return { generate };
}
