import { Color } from './color';
import { Solver } from './solver';

const cache: { [key: string]: string } = {};

export const transformToFilter = (color: string): string => {
  try {
    if (cache.hasOwnProperty(color)) {
      return cache[color];
    }

    const colorInstance = Color.generate(color);
    const { filter } = new Solver(colorInstance as Color).solve();
    if (filter) {
      cache[color] = filter;
      return filter;
    }
  } catch {}
  return 'none';
};
