import { AlertDirection } from '../../../hooks/coordinates-from-buddy-and-direction';
import { BaSeTheme } from '../../../theme';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';
import { AlertColor, AlertThemeMode } from '../alerts-props';

const none = 'none';

const borderSize = 7;
const transparenteBorder = `${borderSize}px solid ${BaSeTheme.colors.defaultColors.transparent}`;
const basePosition = `${-1 * borderSize}px`;
const calcPosition = `calc(50% - ${borderSize}px)`;

const transparenteBorderSmaller = `${borderSize}px solid ${BaSeTheme.colors.defaultColors.transparent}`;
const basePositionSmaller = `${-1 * borderSize + 1}px`;
const calcPositionSmaller = `calc(50% - ${borderSize}px)`;

const replaceColor = (color?: string) =>
  color
    ? `${borderSize}px solid ${color}`
    : `${borderSize}px solid ${BaSeTheme.colors.defaultColors.white}`;

export const getTriangleMapper = (
  themeMode: AlertThemeMode,
  color: AlertColor,
  direction: AlertDirection,
  overrideColors?: string,
  darkColor?: string,
) => {
  const TriangleProps = {
    dark: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePosition,
        positionRight: calcPosition,
        widthTop: `${borderSize}px solid ${
          darkColor ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
        widthRight: transparenteBorder,
        widthBottom: none,
        widthLeft: transparenteBorder,
      },
      right: {
        positionLeft: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: `${borderSize}px solid ${
          darkColor ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
        widthBottom: transparenteBorder,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePosition,
        positionRight: calcPosition,
        widthTop: none,
        widthRight: transparenteBorder,
        widthBottom: `${borderSize}px solid ${
          darkColor ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
        widthLeft: transparenteBorder,
      },
      left: {
        positionRight: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: none,
        widthBottom: transparenteBorder,
        widthLeft: `${borderSize}px solid ${
          darkColor ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
      },
    },
    light: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePosition,
        positionRight: calcPosition,
        widthTop: `${borderSize}px solid ${overrideColors || (color as string)}`,
        widthRight: transparenteBorder,
        widthBottom: none,
        widthLeft: transparenteBorder,
      },
      right: {
        positionLeft: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: `${borderSize}px solid ${overrideColors || (color as string)}`,
        widthBottom: transparenteBorder,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePosition,
        positionRight: calcPosition,
        widthTop: none,
        widthRight: transparenteBorder,
        widthBottom: `${borderSize}px solid ${overrideColors || (color as string)}`,
        widthLeft: transparenteBorder,
      },
      left: {
        positionRight: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: none,
        widthBottom: transparenteBorder,
        widthLeft: `${borderSize}px solid ${overrideColors || (color as string)}`,
      },
    },
  };

  return TriangleProps[themeMode]
    ? TriangleProps[themeMode][direction] ?? TriangleProps[themeMode].neutral
    : TriangleProps.dark.neutral;
};

export const getTriangleMapperSmaller = (
  themeMode: AlertThemeMode,
  color: AlertColor,
  direction: AlertDirection,
  overrideColors?: string,
) => {
  const TrianglePropsSmaller = {
    dark: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: `${borderSize}px solid ${
          overrideColors ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
        widthRight: transparenteBorderSmaller,
        widthBottom: none,
        widthLeft: transparenteBorderSmaller,
      },
      right: {
        positionLeft: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: `${borderSize}px solid ${
          overrideColors ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
        widthBottom: transparenteBorderSmaller,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: none,
        widthRight: transparenteBorderSmaller,
        widthBottom: `${borderSize}px solid ${
          overrideColors ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
        widthLeft: transparenteBorderSmaller,
      },
      left: {
        positionRight: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: none,
        widthBottom: transparenteBorderSmaller,
        widthLeft: `${borderSize}px solid ${
          overrideColors ||
          ColorMapper.create(ColorMapper.getColor(color as string), 0.5)
        }`,
      },
    },
    light: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: replaceColor(overrideColors),
        widthRight: transparenteBorderSmaller,
        widthBottom: none,
        widthLeft: transparenteBorderSmaller,
      },
      right: {
        positionLeft: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: replaceColor(overrideColors),
        widthBottom: transparenteBorderSmaller,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: none,
        widthRight: transparenteBorderSmaller,
        widthBottom: replaceColor(overrideColors),
        widthLeft: transparenteBorderSmaller,
      },
      left: {
        positionRight: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: none,
        widthBottom: transparenteBorderSmaller,
        widthLeft: replaceColor(overrideColors),
      },
    },
  };

  return TrianglePropsSmaller[themeMode]
    ? TrianglePropsSmaller[themeMode][direction] ??
        TrianglePropsSmaller[themeMode].neutral
    : TrianglePropsSmaller.dark.neutral;
};

export const TooltipColorMapper = {
  default: BaSeTheme.colors.institucionais.azulSebrae36,
  destructive: BaSeTheme.colors.feedbackInterface.erro45,
  attention: BaSeTheme.colors.feedbackInterface.atencao55,
  confirmation: BaSeTheme.colors.feedbackInterface.sucesso26,
};
