import * as React from 'react';
import { BaSeProgressElement } from '../../../elements/progress-element';
import {
  ProgressBarBackground,
  ProgressBarWrapped,
} from './progress-bar-styled';
import { useProgress } from '../../../hooks/progress';
import { ProgressProps } from './progress-props';

interface ProgressBarProps extends ProgressProps {
  backgroundColor?: string;
  foregroundColor?: string;
  closeOnTimeout?: boolean;
  loadingDuration: number;
  onFinish?: () => void;
}

export const BaSeProgressBar = React.forwardRef<
  BaSeProgressElement,
  ProgressBarProps
>(
  (
    {
      backgroundColor,
      foregroundColor,
      closeOnTimeout,
      loadingDuration,
      type = 'emptying',
      size = 6,
      onFinish,
    }: React.PropsWithChildren<ProgressBarProps>,
    ref: React.ForwardedRef<BaSeProgressElement>,
  ) => {
    const { loading, isRunning } = useProgress({
      loadingDuration,
      closeOnTimeout,
      type,
      onFinish,
      ref,
    });

    if (loading || !closeOnTimeout) {
      return (
        <>
          <ProgressBarBackground size={size} color={backgroundColor} />
          <ProgressBarWrapped
            size={size}
            aria-label="progress bar"
            role="progressbar"
            color={foregroundColor}
            animationDuration={loadingDuration}
            animationPlayStyle={isRunning ? 'running' : 'paused'}
            type={type ? type : 'emptying'}
          />
        </>
      );
    }

    return null;
  },
);

BaSeProgressBar.displayName = 'BaSeProgressBar';
