import * as React from 'react';
import { BaSeButtonElement } from '../../../elements/button-element';
import { objectNotEmpty } from '../../../utils/object-utils';
import { BaSePopover, PopoverProps } from '../../alerts/popover/popover';
import { BaSeTooltip, TooltipProps } from '../../alerts/tooltip/tooltip';
import { ButtonProps } from '../button-props';
import { BaSeIconButton, IconButtonProps } from './icon-button';

export type ShapeButtonProps = Omit<
  IconButtonProps,
  'onMouseEnter' | 'onMouseLeave'
> &
  Pick<ButtonProps, 'tooltip' | 'popover'>;

export const BaSeShapeButton = React.forwardRef<
  BaSeButtonElement,
  ShapeButtonProps
>(({ tooltip, popover, onClick, ...iconButtonProps }, forwardedRef) => {
  if (objectNotEmpty(tooltip) && objectNotEmpty(popover)) {
    throw Error(
      '[BaSeShapeButton] error: Informe só o "tooltip" ou o "popover"!',
    );
  }

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  React.useImperativeHandle(
    forwardedRef,
    () => ({
      focus: () => buttonRef?.current?.focus?.(),
      click: () => buttonRef?.current?.click?.(),
    }),
    [buttonRef],
  );

  const [isTooltipOrPopoverOpen, setIsTooltipOrPopoverOpen] =
    React.useState(false);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      buttonRef?.current?.focus();
      onClick(event);
      const timeout = setTimeout(() => {
        if (buttonRef.current?.isConnected) {
          setIsTooltipOrPopoverOpen(false);
        }
      }, 1);
      return () => clearTimeout(timeout);
    },
    [onClick, buttonRef],
  );

  return (
    <BaSeIconButton
      {...iconButtonProps}
      ref={buttonRef}
      onClick={handleClick}
      onMouseEnter={() => setIsTooltipOrPopoverOpen(true)}
      onMouseLeave={() => setIsTooltipOrPopoverOpen(false)}
    >
      {objectNotEmpty(tooltip) && (
        <BaSeTooltip
          buddyRef={buttonRef}
          open={isTooltipOrPopoverOpen}
          {...(tooltip as Omit<TooltipProps, 'buddyRef' | 'open'>)}
        />
      )}
      {objectNotEmpty(popover) && (
        <BaSePopover
          buddyRef={buttonRef}
          open={isTooltipOrPopoverOpen}
          hasCloseButton={false}
          {...(popover as Omit<
            PopoverProps,
            'buddyRef' | 'open' | 'hasCloseButton'
          >)}
        />
      )}
    </BaSeIconButton>
  );
});

BaSeShapeButton.displayName = 'BaSeShapeButton';
