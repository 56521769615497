import { BaSeTheme } from '.';
import { ThemeNamedColor } from './theme-interface';

export const AlertColorTypes = {
  attention: {
    background: BaSeTheme.colors.feedbackInterface.atencao75,
    foreground: BaSeTheme.colors.feedbackInterface.atencao55,
  },
  confirmation: {
    background: BaSeTheme.colors.feedbackInterface.sucesso85,
    foreground: BaSeTheme.colors.feedbackInterface.sucesso45,
  },
  default: {
    background: BaSeTheme.colors.institucionais.cinzaSebrae90,
    foreground: BaSeTheme.colors.institucionais.azulSebrae60,
  },
  destructive: {
    background: BaSeTheme.colors.gestao.vermelho90,
    foreground: BaSeTheme.colors.feedbackInterface.erro35,
  },
};

export const MessageColorTypes = {
  attention: {
    foreground: BaSeTheme.colors.feedbackInterface.atencao35,
    background: BaSeTheme.colors.feedbackInterface.atencao75,
  },
  confirmation: {
    foreground: BaSeTheme.colors.feedbackInterface.sucesso20,
    background: BaSeTheme.colors.feedbackInterface.sucesso85,
  },
  default: {
    foreground: BaSeTheme.colors.institucionais.azulSebrae36,
    background: BaSeTheme.colors.institucionais.azulSebrae75,
  },
  destructive: {
    foreground: BaSeTheme.colors.feedbackInterface.erro35,
    background: BaSeTheme.colors.gestao.vermelho90,
  },
};

export const getAlertColorFromType = (type: ThemeNamedColor) =>
  AlertColorTypes[type] ?? AlertColorTypes.default;

export const getMessageColorFromType = (type: ThemeNamedColor) =>
  MessageColorTypes[type] ?? MessageColorTypes.default;
