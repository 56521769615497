import { ThemeTransitionsInterface } from './theme-interface';

const continuousTimingFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';
const elasticTimingFunction = 'cubic-bezier(0.18, 0.89, 0.67, 1.57)';

const durationNormalInMilliseconds = 255;
const durationSlowInMilliseconds = 1000;
const durationFastInMilliseconds = 120;

const durationNormal = `${durationNormalInMilliseconds}ms`;
const durationSlow = `${durationSlowInMilliseconds}ms`;
const durationFast = `${durationFastInMilliseconds}ms`;

function transition(duration: string, timingFunction: string): string {
  return `all ${duration} ${timingFunction}`;
}

export const BaSeTransitions: ThemeTransitionsInterface = {
  allPropertiesContinuousNormal: transition(
    durationNormal,
    continuousTimingFunction,
  ),
  allPropertiesContinuousSlow: transition(
    durationSlow,
    continuousTimingFunction,
  ),
  allPropertiesContinuousFast: transition(
    durationFast,
    continuousTimingFunction,
  ),
  allPropertiesElasticNormal: transition(durationNormal, elasticTimingFunction),
  allPropertiesElasticLenta: transition(durationSlow, elasticTimingFunction),
  allPropertiesElasticRapida: transition(durationFast, elasticTimingFunction),
  continuousTimingFunction,
  elasticTimingFunction,
  durationNormalInMilliseconds,
  durationSlowInMilliseconds,
  durationFastInMilliseconds,
  durationNormal,
  durationSlow,
  durationFast,
};
