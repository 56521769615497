export type LooseString<Options extends string> =
  | Options
  | Omit<string, Options>;

function _forceString(text: string = '', trimmed: boolean = true): string {
  if (typeof text !== 'string') {
    text = '';
  }
  return trimmed ? text?.trim() : text;
}

/**
 * Limpa a(s) barra(s) no final da URL informada
 *
 * @param url
 */
export function urlSanitizer(url: string): string {
  return _forceString(url).replace(/\/$/, '');
}

/**
 * Atalho para `encodeURIComponent()`
 * @param url Algo que precisa de ser codificado
 */
export function urlEncode(url: string, simpleEncode: boolean = false): string {
  const encode = (term: string) => {
    const _url = new URL('https://fake.url');
    _url.searchParams.set('key', term);
    return _url.search.replace('?key=', '');
  };
  return simpleEncode ? encodeURIComponent(url) : encode(url);
}

/**
 * Atalho para `decodeURIComponent()`
 * @param url Algo que precisa de ser decodificado
 */
export function urlDecode(url: string, simpleDecode: boolean = false): string {
  const decode = (term: string) =>
    new URL(`https://fake.url?key=${term}`).searchParams.get('key') ??
    decodeURIComponent(term);
  return simpleDecode ? decodeURIComponent(url) : decode(url);
}

/**
 * Atalho para codificar em base64 usando UTF-8
 * @param str Texto a ser codificado
 */
export function base64Encode(str: string): string {
  return btoa(
    urlEncode(str).replace(/%([0-9A-F]{2})/g, (_: string, char: string) =>
      String.fromCharCode(parseInt('0x' + char, 16)),
    ),
  );
}

/**
 * Atalho para decodificar um texto em UTF-8 na codificação base64
 * @param base64 Texto a ser decodificado
 */
export function base64Decode(base64: string): string {
  return urlDecode(
    atob(base64)
      .split('')
      .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

/**
 * Atalho para decodificar um texto em UTF-8 na codificação base64
 * Retornando um TypedArray: Uint8Array
 * @param base64 Texto a ser decodificado
 */
export function base64DecodeToTypedArray(base64: string): Uint8Array {
  const binaryString = base64Decode(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

/**
 * Atalho para decodificar um texto em UTF-8 na codificação base64
 * Retornando um Blob
 * @param base64 Texto a ser decodificado
 * @param contentType Mime type do Blob
 */
export function base64DecodeToBlob(base64: string, contentType: string): Blob {
  contentType = contentType || 'application/octet-stream';
  return new Blob([base64DecodeToTypedArray(base64)], { type: contentType });
}

/**
 * Atalho para para converter uma string em objeto Blob
 * Retornando um Blob
 * @param str Texto a ser decodificado
 * @param contentType Mime type do Blob
 */
export function stringToBlob(
  str: string,
  contentType: string = 'application/octet-stream',
): Blob {
  return new Blob([stringToTypedArray(str)], { type: contentType });
}

/**
 * Atalho para decodificar um texto em TypedArray
 * Retornando um TypedArray: Uint8Array
 * @param str Texto a ser decodificado
 */
export function stringToTypedArray(str: string): Uint8Array {
  const binaryLen = str.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}

/**
 * Verifica se o valor passado contém um número válido
 * Retornando um boolean
 * @param value Valor a ser validado como númerico
 */
export function isNumeric(value: string): boolean {
  return !isNaN(Number(value));
}

/*
 * Remove todas as tags de um HTML informado
 * Retornando o texto sem as tags
 * @param html
 */
export function stripHtmlTags(html: string = ''): string {
  return _forceString(html, false).replace(/<[^>]*>/g, '');
}

/**
 * Normaliza o texto informado para minúsculo e sem acentuações
 * @param text
 */
export function normalize(text: string = ''): string {
  text = _forceString(text);
  if (!text) {
    return '';
  }
  let normalized = String(text).toLowerCase().trim();
  const map = {
    a: '[àáâãäå]',
    e: '[èéêë]',
    i: '[ìíîï]',
    o: '[òóôõö]',
    u: '[ùúûűü]',
    y: '[ýÿ]',
    c: 'ç',
    n: 'ñ',
    '-': '–',
    ' ': '\\s+',
  } as const;
  let i: keyof typeof map;
  for (i in map) {
    if (map.hasOwnProperty(i)) {
      normalized = normalized.replace(new RegExp(map[i], 'img'), i);
    }
  }
  normalized = normalized.replace(/\s+/g, ' ');
  return normalized;
}

/**
 * Aplica a abreviação retornando a sigla do nome de acordo com o 1º e o ultimo nome
 * @param name Nome a ser abreviado
 */
export function nameAbbreviation(name: string = ''): string {
  name = _forceString(name);
  const filterRegex = /^D[AEO]$/g;
  const nameSplitted = (name?.toUpperCase()?.split(' ') ?? [])
    .map((term) => term.trim())
    .filter((term) => !filterRegex.test(term));
  const firstIndex = 0;
  const lastIndex = nameSplitted.length - 1;
  const firstName = nameSplitted[firstIndex];
  const lastName = firstIndex < lastIndex ? nameSplitted[lastIndex] : '';
  const first = Array.from(firstName)?.[0] ?? '';
  const last = Array.from(lastName)?.[0] ?? '';
  return `${first}${last}`;
}

/**
 * Retorna a primeira letra maiuscula do texto informado
 * @param text Texto a ser modificado
 */
export function firstLetterUpperCase(text: string = ''): string {
  text = _forceString(text);
  const firstLetter = text?.charAt(0)?.toUpperCase();
  const othersLetters = text?.substr(1);
  return firstLetter + othersLetters;
}

/**
 * Retorna o valor com a unidade
 * @param value Valor com a unidade ou só o número para concatenar com a unidade padrão. Caso passado undefined retorna "0"
 * @param unit Unidade de retono caso passado um número no value. Unidade padrão "px"
 */
export function numberWithUnit(
  value?: number | string | null,
  unit: 'px' | 'rem' | 'em' | '%' | 'fr' = 'px',
  empty: string = 'initial',
): string {
  if (!value) {
    return empty;
  }
  return isNumeric(value as string) ? `${value}${unit}` : `${value}`;
}

/**
 * Converte o input em camelCase. Exemplo passando 'um-dois-tres' vai retornar umDoisTres
 * @param input Valor de entrada
 * @param separator Separador das palavras. Padrão: "-"
 * @returns A string no padrão camelCase
 */
export function camelCase(input: string, separator: string = '-'): string {
  const regExp = new RegExp(`\\${separator.toLowerCase()}(.)`, 'g');
  return input
    .toLowerCase()
    .replace(regExp, (_, letter: string) => letter?.toUpperCase?.());
}
/**
 * Converte o input em kebabCase. Exemplo passando 'Um dois tres' vai retornar um-dois-tres
 * @param input Valor de entrada
 * @returns A string no padrão kebab-case
 */
export const toKebabCase = (input: string) => {
  return input
    .replace(/\s{1,}/, '-')
    .replace(/[A-Z]/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : '-' + letter.toLowerCase(),
    )
    .replace(/-{2,}/, '-');
};

/**
 * Inverte o texto
 * @param input Texto a ser invertido
 * @returns Texto invertido
 */
export function reverse(input: string): string {
  return input.split('').reverse().join('');
}

/**
 * Converte a lista informada no texto juntado
 * @param list Lista com os textos que vão ser juntados
 * @param separator Separador
 * @param finalSeparator Separador final (caso omitido usa o valor do `separator`)
 * @returns O texto juntado com os devidos separadores
 */
export function joinWithSeparators(
  list: string[],
  separator?: string,
  finalSeparator?: string,
): string {
  const escape = (str?: string) =>
    str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') ?? '';
  const reverseSeparator = reverse(separator ?? ', ');
  const reverseFinalSeparator = reverse(finalSeparator ?? separator ?? ' e ');
  const regExp = new RegExp(`${escape(reverseSeparator)}(.*$)`);
  const reverseText = list
    .map(reverse)
    .reverse()
    .join(reverseSeparator)
    .replace(regExp, `${reverseFinalSeparator}$1`);
  return reverse(reverseText);
}

/**
 * Retorna o texto sem os acentos
 * @param text Texto a ter os acentos removidos
 */
export function removeAccents(text: string = ''): string {
  text = _forceString(text);
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
