import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../../utils/string-utils';
import { BaSeTheme } from '../../../theme';

interface StyledButtonProps {
  backgroundColor: string;
  backgroundOnHover: string;
  width: string;
  minWidth: number;
  border: string;
  justifyContent: string;
  boxShadowFocus: string;
  padding: string;
  disabled: boolean;
  type: string;
  hoverColor?: string;
  hideBorder?: boolean;
  boxShadow?: boolean;
  thickBorder?: boolean;
  medium?: boolean;
  borderRadius?: number;
}

interface ButtonSize {
  size: string;
}

const buttonWidth = (width: number | string) => css`
  width: ${numberWithUnit(width)};
`;

const getButtonRadius = (radius?: number) => {
  if (!radius && radius !== 0) {
    return '4px';
  }
  return `${radius}px`;
};

export const Button = styled.button.attrs({
  className: 'BaSe--button',
})<StyledButtonProps>`
  &.BaSe--button {
    box-sizing: border-box;
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
    line-height: ${BaSeTheme.typography.lineHeightBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  }

  ${({
    backgroundColor,
    width,
    border,
    padding,
    minWidth,
    justifyContent,
    backgroundOnHover,
    boxShadowFocus,
    hideBorder,
    boxShadow,
    thickBorder,
    medium,
    borderRadius,
  }) => css`
    background-color: ${backgroundColor};
    ${buttonWidth(width)}
    min-width: ${minWidth}px;
    border: ${border};
    padding: ${padding};
    justify-content: ${justifyContent};
    border-radius: ${getButtonRadius(borderRadius)};
    ${medium &&
    css`
      min-height: 40px;
    `}
    ${boxShadow &&
    css`
      box-shadow: ${BaSeTheme.shadows.profundidade2};
    `}

    &:hover {
      cursor: pointer;
      background-color: ${backgroundOnHover};
    }

    &:focus {
      outline: none;
      box-shadow: ${boxShadowFocus};
      ${hideBorder &&
      `border: ${thickBorder ? 2 : 1}px solid ${
        BaSeTheme.colors.defaultColors.transparent
      };`}
    }

    &:active:not(:disabled) {
      box-shadow: none;
      transform: translateY(2px);
    }

    &:disabled {
      cursor: not-allowed;
    }
  `};
`;

export const SpinnerContainer = styled.div<ButtonSize>`
  ${({ size }) => css`
    margin-right: ${size === 'big' ? 12 : 4}px;
    display: grid;
    place-items: center;
  `}
`;
