import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledParagraph } from './paragraph-styled';

export const BaSeParagraph: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={1}
    lineHeight={1.46}
    wrapper={StyledParagraph}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeParagraph.displayName = 'BaSeParagraph';
