import * as React from 'react';
import {
  getNextHash,
  getNextCounter,
  IdGenerator,
} from '../utils/id-generator';

export function useNextHashId(idSequence: IdGenerator): string {
  return React.useMemo(() => getNextHash(idSequence), []);
}

export function useNextCounterId(idSequence: IdGenerator): number {
  return React.useMemo(() => getNextCounter(idSequence), []);
}
