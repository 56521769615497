import { KeycloakInstance } from 'keycloak-js';
import { GetterConfigByAuthServerUrl } from '../../../hooks/config-by-auth-server-url';
import { urlSanitizer } from '../../../utils/string-utils';

interface GetAvatarArgs {
  getConfig: GetterConfigByAuthServerUrl;
  avatarUrl?: string;
  userId?: string;
  keycloak?: KeycloakInstance;
}

interface ExtractFullNameArgs {
  getConfig: GetterConfigByAuthServerUrl;
  keycloak?: KeycloakInstance;
}

const emptyId = 'O.o';

export function getAvatarUrl({
  getConfig,
  avatarUrl,
  userId = emptyId,
  keycloak,
}: GetAvatarArgs): string {
  const tokenAvatarKey = getConfig({
    authServerUrl: keycloak?.authServerUrl,
    key: 'jwtAttrAvatar',
  });
  const attrAvatar = tokenAvatarKey
    ? (keycloak?.tokenParsed?.[tokenAvatarKey] as string)
    : undefined;

  if (attrAvatar) {
    return attrAvatar;
  }

  if (userId === emptyId) {
    return '';
  }

  return `${urlSanitizer(avatarUrl as string)}/${userId}`;
}

export function extractFullName({
  getConfig,
  keycloak,
}: ExtractFullNameArgs): string | undefined {
  const tokenNameKey = getConfig({
    authServerUrl: keycloak?.authServerUrl,
    key: 'jwtAttrName',
  });
  const name = tokenNameKey
    ? (keycloak?.tokenParsed?.[tokenNameKey] as string)
    : undefined;

  if (name) {
    return name;
  }

  const tokenGivenNameKey = getConfig({
    authServerUrl: keycloak?.authServerUrl,
    key: 'jwtAttrGivenName',
  });

  const givenName = tokenGivenNameKey
    ? (keycloak?.tokenParsed?.[tokenGivenNameKey] as string)
    : undefined;

  const tokenFamilyNameKey = getConfig({
    authServerUrl: keycloak?.authServerUrl,
    key: 'jwtAttrFamilyName',
  });

  const familyName = tokenFamilyNameKey
    ? (keycloak?.tokenParsed?.[tokenFamilyNameKey] as string)
    : undefined;

  if (givenName && familyName) {
    return `${givenName} ${familyName}`;
  }

  const tokenUsernameKey = getConfig({
    authServerUrl: keycloak?.authServerUrl,
    key: 'jwtAttrUsername',
  });

  return tokenUsernameKey
    ? (keycloak?.tokenParsed?.[tokenUsernameKey] as string)
    : undefined;
}
