import { AlertDirection } from '../../../hooks/coordinates-from-buddy-and-direction';
import { BaSeTheme } from '../../../theme';
import { transformLightness } from '../../../utils/color-transformation/transform-lightness';
import { AlertColor, AlertThemeMode } from '../alerts-props';

const none = 'none';

const borderSize = 7;
const transparenteBorder = `${borderSize}px solid ${BaSeTheme.colors.defaultColors.transparent}`;
const basePosition = `${-1 * borderSize}px`;
const calcPosition = `calc(50% - ${borderSize}px)`;

const borderSizeSmaller = 6.5;
const filledBorderSmaller = (color?: string) =>
  color
    ? `${borderSizeSmaller}px solid ${color}`
    : `${borderSizeSmaller}px solid ${BaSeTheme.colors.defaultColors.white}`;
const transparenteBorderSmaller = `${borderSizeSmaller}px solid ${BaSeTheme.colors.defaultColors.transparent}`;
const basePositionSmaller = `${-1 * borderSizeSmaller + 1}px`;
const calcPositionSmaller = `calc(50% - ${borderSizeSmaller}px)`;

export const getTriangleMapper = (
  themeMode: AlertThemeMode,
  color: AlertColor,
  direction: AlertDirection,
  overrideColor?: string,
) => {
  const TriangleProps = {
    dark: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePosition,
        positionRight: calcPosition,
        widthTop: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
        widthRight: transparenteBorder,
        widthBottom: none,
        widthLeft: transparenteBorder,
      },
      right: {
        positionLeft: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
        widthBottom: transparenteBorder,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePosition,
        positionRight: calcPosition,
        widthTop: none,
        widthRight: transparenteBorder,
        widthBottom: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
        widthLeft: transparenteBorder,
      },
      left: {
        positionRight: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: none,
        widthBottom: transparenteBorder,
        widthLeft: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
      },
    },
    light: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePosition,
        positionRight: calcPosition,
        widthTop: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.9)
        }`,
        widthRight: transparenteBorder,
        widthBottom: none,
        widthLeft: transparenteBorder,
      },
      right: {
        positionLeft: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.9)
        }`,
        widthBottom: transparenteBorder,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePosition,
        positionRight: calcPosition,
        widthTop: none,
        widthRight: transparenteBorder,
        widthBottom: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.9)
        }`,
        widthLeft: transparenteBorder,
      },
      left: {
        positionRight: basePosition,
        positionBottom: calcPosition,
        widthTop: transparenteBorder,
        widthRight: none,
        widthBottom: transparenteBorder,
        widthLeft: `${borderSize}px solid ${
          overrideColor || transformLightness(color as string, 0.9)
        }`,
      },
    },
  };

  return TriangleProps[themeMode]
    ? TriangleProps[themeMode][direction] ?? TriangleProps[themeMode].neutral
    : TriangleProps.dark.neutral;
};

export const getTriangleMapperSmaller = (
  themeMode: AlertThemeMode,
  color: AlertColor,
  direction: AlertDirection,
  overrideColor?: string,
) => {
  const TrianglePropsSmaller = {
    dark: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: `${borderSizeSmaller}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
        widthRight: transparenteBorderSmaller,
        widthBottom: none,
        widthLeft: transparenteBorderSmaller,
      },
      right: {
        positionLeft: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: `${borderSizeSmaller}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
        widthBottom: transparenteBorderSmaller,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: none,
        widthRight: transparenteBorderSmaller,
        widthBottom: `${borderSizeSmaller}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
        widthLeft: transparenteBorderSmaller,
      },
      left: {
        positionRight: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: none,
        widthBottom: transparenteBorderSmaller,
        widthLeft: `${borderSizeSmaller}px solid ${
          overrideColor || transformLightness(color as string, 0.15)
        }`,
      },
    },
    light: {
      neutral: {
        widthTop: none,
        widthRight: none,
        widthBottom: none,
        widthLeft: none,
      },
      top: {
        positionBottom: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: filledBorderSmaller(overrideColor),
        widthRight: transparenteBorderSmaller,
        widthBottom: none,
        widthLeft: transparenteBorderSmaller,
      },
      right: {
        positionLeft: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: filledBorderSmaller(overrideColor),
        widthBottom: transparenteBorderSmaller,
        widthLeft: none,
      },
      bottom: {
        positionTop: basePositionSmaller,
        positionRight: calcPositionSmaller,
        widthTop: none,
        widthRight: transparenteBorderSmaller,
        widthBottom: filledBorderSmaller(overrideColor),
        widthLeft: transparenteBorderSmaller,
      },
      left: {
        positionRight: basePositionSmaller,
        positionBottom: calcPositionSmaller,
        widthTop: transparenteBorderSmaller,
        widthRight: none,
        widthBottom: transparenteBorderSmaller,
        widthLeft: filledBorderSmaller(overrideColor),
      },
    },
  };

  return TrianglePropsSmaller[themeMode]
    ? TrianglePropsSmaller[themeMode][direction] ??
        TrianglePropsSmaller[themeMode].neutral
    : TrianglePropsSmaller.dark.neutral;
};

export const PopoverColorMapper = {
  default: BaSeTheme.colors.institucionais.azulSebrae36,
  destructive: BaSeTheme.colors.feedbackInterface.erro45,
  attention: BaSeTheme.colors.feedbackInterface.atencao55,
  confirmation: BaSeTheme.colors.feedbackInterface.sucesso26,
};
