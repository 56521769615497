import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';
import {
  AlertStyledProps,
  AlertTriangleProps,
  AlertThemeProps,
} from '../alerts-props';

export const TooltipContainer = styled.div.attrs({
  className: 'BaSe--tooltip',
})<AlertStyledProps>`
  z-index: ${BaSeTheme.stackOrder.aboveNormal};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  will-change: opacity;
  position: absolute;

  ${(props) =>
    props.open
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
  ${(props) =>
    !props.open &&
    css`
      pointer-events: none;
    `}
  opacity: ${(props) => (props.open ? 1 : 0)};
  ${(props) =>
    props.top &&
    css`
      top: ${props.top};
    `}
  ${(props) =>
    props.right &&
    css`
      right: ${props.right};
    `}
  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `}
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
  ${(props) => css`
    width: ${props.width ? `${props.width}px` : 'auto'};
  `}
`;

export const TooltipBody = styled.div.attrs({
  className: 'BaSe--tooltip-body',
})<AlertThemeProps>`
  background-color: ${(props) =>
    props.themeMode === 'light'
      ? ColorMapper?.getColor(props?.overrideColors?.backgroundColor) ||
        BaSeTheme.colors.defaultColors.white
      : ColorMapper.getColor(props?.overrideColors?.backgroundColor) ||
        ColorMapper.create(ColorMapper.getColor(props?.color), 0.5)};
  ${(props) =>
    props.themeMode === 'light' &&
    `border: 1px solid ${props?.overrideColors?.borderColor ?? props.color};`}
  border-radius: 4px;
  ${({ size = 'medium' }) => css`
    padding: ${
      size === 'small' ? '4px' : size === 'big' ? '12px' : '8px 12px' // medium
    };
  `}
  position: relative;
`;

export const MessageWrapper = styled.span<AlertThemeProps>`
  display: flex;
  flex-direction: column;

  ${({ messageNoBreakLine }) =>
    messageNoBreakLine &&
    css`
      white-space: nowrap;
    `}

  & > small[class*=BaSe] {
    color: ${({ color, themeMode, overrideColors }) =>
      overrideColors?.foreground ||
      (themeMode === 'light' ? color : BaSeTheme.colors.defaultColors.white)};
    word-wrap: break-word;
  }
`;

export const TooltipTriangle = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.stackOrder.aboveNormal + 1};
`;

export const TooltipTriangleSmaller = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.stackOrder.aboveNormal + 2};
`;
