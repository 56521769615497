import styled, { css } from 'styled-components';
import { AlertCoordinates } from '../../hooks/coordinates-from-buddy-and-direction';
import { BaSeTheme } from '../../theme';

export interface TutorialPopupCardWrapper extends AlertCoordinates {
  isOpen: boolean;
}

export interface TutorialCardTriangleProps extends TutorialPopupCardWrapper {
  degre: number;
}

export interface TutorialCardProps {
  innerWidth: number;
  isOpen: boolean;
}

export const TutorialPopupCardWrapper = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  position: absolute;
  will-change: top, left, opacity;
`;

export const TutorialPopupCard = styled.div<TutorialCardProps>`
  ${({ innerWidth, isOpen }) => css`
    width: ${innerWidth}px;
    opacity: ${+isOpen};
  `}
  box-sizing: border-box;
  background: #fff;
  border: 1px solid ${BaSeTheme.colors.institucionais.azulSebrae75};
  border-radius: 0.75em;
  padding: 0.75em 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  min-height: 115px;
`;

export const TutorialPopupCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TutorialPopupCardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  z-index: ${BaSeTheme.stackOrder.overlay};
  width: 100%;
  left: 1em;
  bottom: 1em;
  width: calc(100% - 2em);
  box-sizing: border-box;
  gap: 0.5em;
`;

export const TutorialPopupCardFooterButtons = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin-left: auto;
  position: absolute;
`;

export const TutorialPopupTriangle = styled.div<TutorialCardTriangleProps>`
  ${({ top, left, degre, isOpen }) => css`
    top: ${top}px;
    left: ${left}px;
    opacity: ${+isOpen};
    transform: rotate(${degre}deg);
    border-top-right-radius: 0.25em;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.5em 0.86em 0.5em;
    border-color: transparent transparent #fff transparent;
    z-index: ${BaSeTheme.stackOrder.default + 1};
  `}
`;

export const TutorialCardButtonWrapper = styled.div`
  width: 2.5em;
  height: 2.5em;
  box-sizing: border-box;
  display: grid;
  place-items: center;
`;
