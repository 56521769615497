import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

interface ContainerButtonStyledProps {
  focusBoxShadowColor: string;
  hoverBackgroundColor: string;
  backgroundColor: string;
  iconSize: number;
  textColor: string;
  iconColor: string;
  transition: string;
  isAuthenticated: boolean;
}

export const ContainerButtonStyled = styled.button<ContainerButtonStyledProps>`
  ${({
    iconSize,
    backgroundColor,
    textColor,
    transition,
    focusBoxShadowColor,
    hoverBackgroundColor,
    iconColor,
    isAuthenticated,
  }) => css`
    line-height: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${BaSeTheme.typography.fontSizeBase};
    display: grid;
    gap: 4px;
    grid-template-columns: ${isAuthenticated ? iconSize * 2 + 2 : iconSize}px 1fr;
    place-items: center;
    background: ${backgroundColor};
    border-radius: 4px;
    border: none;
    outline: none;
    color: ${textColor};
    overflow: hidden;
    transition: ${transition};
    padding: 8px;
    margin: 0;

    &,
    * {
      font-family: ${BaSeTheme.typography.fontFamily};
    }

    &:focus,
    &:focus-within,
    &:hover,
    &:active {
      cursor: pointer;
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:focus,
    &:focus-within {
      box-shadow: 0px 0px 0px 3px ${focusBoxShadowColor};
    }

    &:hover,
    &:active {
      background-color: ${hoverBackgroundColor};
    }

    .BaSe--account-profile-avatar,
    .BaSe--account-profile-name-abbr-icon {
      background: ${textColor};
      width: ${iconSize * 2 + 2}px;
      height: ${iconSize * 2 + 2}px;
      border-radius: ${iconSize + 1}px;
      overflow: hidden;
    }

    .BaSe--account-profile-avatar {
      object-fit: contain;
    }

    .BaSe--account-profile-name-abbr-icon {
      display: grid;
      place-items: center;
      color: ${iconColor};
      line-height: ${iconSize}px;
      font-size: 13px;
    }

    .BaSe--account-profile-name {
      display: inline;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100px;
      font-size: 13px;
    }
  `}
`;
