import * as React from 'react';
import { getNextCounter, idGenerator } from '../utils/id-generator';
import { BaSeModal } from '../components/dialog/modal';
import { BaSeNotification } from '../components/dialog/notification';
import { ThemeColorValue, ThemeNamedType } from '../theme/theme-interface';
import { ThemeBreakpointType } from '../theme/theme-interface';
import {
  BaSeDialogTutorial,
  TutorialStepsProps,
} from '../components/tutorial/tutorial';

const idSequence = idGenerator();

function addInPool<State>(
  setPoolState: React.Dispatch<React.SetStateAction<State[]>>,
  itemToAdd: State,
): void {
  setPoolState((pool) => [...pool, itemToAdd]);
}

function removeFromPool<State extends { id: number }>(
  setPoolState: React.Dispatch<React.SetStateAction<State[]>>,
  itemToRemove: State,
): void {
  setPoolState((pool) => pool.filter((item) => item.id !== itemToRemove.id));
}

export interface BaSeDialogModalActionsInterface {
  action: string;
  color?: ThemeColorValue;
  type?: ThemeNamedType;
  loadingText?: string;
}

type FunctionElement = () => JSX.Element;

export interface RenderContentProps {
  closeModal: (action: string) => void;
  emitEvent: <Payload>(name: string, payload?: Payload) => void;
}

export interface BaSeDialogModalImageInterface {
  src: string;
  alt: string;
}

export interface BaSeDialogModalInterface {
  id: number;
  title: string | FunctionElement;
  titleColor?: ThemeColorValue;
  size: 'small' | 'medium' | 'large';
  actions?: (string | BaSeDialogModalActionsInterface)[];
  alignActions?: 'center' | 'start' | 'end';
  dismissible?: boolean;
  dismissibleIconColor?: string;
  dismissibleIconType?: ThemeNamedType;
  dismissibleIconName?: string;
  isFluid?: boolean;
  breakpointDelimiter?: ThemeBreakpointType;
  rightImage?: BaSeDialogModalImageInterface;
  leftImage?: BaSeDialogModalImageInterface;
  onCancel(): void;
  onClose(action: string): void;
  beforeClose?(action: string): Promise<boolean> | boolean;
  onContentEvent?<Payload>(name: string, payload?: Payload): void;
  content?(rendeContentProps: RenderContentProps): JSX.Element;
}

export type BaSeDialogNotificationColorType =
  | 'attention'
  | 'confirmation'
  | 'default'
  | 'destructive';

export interface BaSeDialogNotificationInterface {
  id: number;
  color: BaSeDialogNotificationColorType;
  message: string;
  icon?: string | false;
  supportMessage?: string;
  showCloseButton?: boolean;
  secondsToClose?: number;
  progressAnimationType?: 'circle' | 'bar';
  action?: string;
  onClose(forced: boolean): void;
  onAction(): void;
}

export type BaSeDialogModalConfig = Pick<
  BaSeDialogModalInterface,
  | 'actions'
  | 'alignActions'
  | 'beforeClose'
  | 'breakpointDelimiter'
  | 'content'
  | 'dismissible'
  | 'dismissibleIconColor'
  | 'dismissibleIconName'
  | 'dismissibleIconType'
  | 'isFluid'
  | 'leftImage'
  | 'onContentEvent'
  | 'rightImage'
  | 'size'
  | 'title'
  | 'titleColor'
> & {
  onChooseAction?(action: string): void;
  onOpen?(): void;
  onDismiss?(): void;
  afterClose?(): void;
};

export type BaSeDialogNotificationConfig = Pick<
  BaSeDialogNotificationInterface,
  | 'color'
  | 'message'
  | 'supportMessage'
  | 'showCloseButton'
  | 'secondsToClose'
  | 'action'
  | 'icon'
  | 'progressAnimationType'
> & {
  onOpen?(): void;
  onActionClose?(): void;
  onForceClose?(): void;
  onAutoClose?(): void;
  afterClose?(): void;
};

export interface BaSeDialogTutorialConfig {
  steps: TutorialStepsProps[];
  dismissible?: boolean;
  beforeClose?: () => boolean;
  afterClose?: () => void;
}

export interface BaSeDialogTutorialInterface extends BaSeDialogTutorialConfig {
  onClose: () => void;
  id: number;
}

interface DialogContainerProps {
  modalPool: BaSeDialogModalInterface[];
  notificationPool: BaSeDialogNotificationInterface[];
  tutorialPool: BaSeDialogTutorialInterface[];
}
export interface BaSeDialogContextInterface {
  DialogContainer: JSX.Element;
  showModal(config: BaSeDialogModalConfig): void;
  showNotification(config: BaSeDialogNotificationConfig): void;
  showTutorial(config: BaSeDialogTutorialConfig): void;
}

const DialogContainer: React.FC<DialogContainerProps> = ({
  modalPool,
  notificationPool,
  tutorialPool,
}) => {
  return (
    <>
      {modalPool.map(({ id, ...rest }) => (
        <BaSeModal key={id} {...rest} />
      ))}
      {tutorialPool.map(({ id, ...rest }) => (
        <BaSeDialogTutorial key={id} {...rest} />
      ))}
      {notificationPool.map(({ id, ...rest }) => (
        <BaSeNotification key={id} {...rest} />
      ))}
    </>
  );
};

export const BaSeDialogContext =
  React.createContext<BaSeDialogContextInterface>({
    DialogContainer: <></>,
    showModal: () => {},
    showNotification: () => {},
    showTutorial: () => {},
  });

export const BaSeDialogProvider: React.FC = ({ children }) => {
  const [modalPool, setModalPool] = React.useState<BaSeDialogModalInterface[]>(
    [],
  );
  const [notificationPool, setNotificationPool] = React.useState<
    BaSeDialogNotificationInterface[]
  >([]);
  const [tutorialPool, setTutorialPool] = React.useState<
    BaSeDialogTutorialInterface[]
  >([]);

  const showModal = React.useCallback((config: BaSeDialogModalConfig) => {
    const {
      size,
      title,
      titleColor,
      actions,
      alignActions,
      dismissible,
      dismissibleIconColor,
      dismissibleIconType,
      dismissibleIconName,
      isFluid,
      breakpointDelimiter,
      content,
      onContentEvent,
      beforeClose,
      rightImage,
      leftImage,
    } = config;

    const id = getNextCounter(idSequence);
    const modal: BaSeDialogModalInterface = {
      id,
      size,
      title,
      titleColor,
      actions,
      alignActions,
      dismissible,
      dismissibleIconColor,
      dismissibleIconType,
      dismissibleIconName,
      isFluid,
      breakpointDelimiter,
      content,
      onContentEvent,
      beforeClose,
      onCancel: () => {
        config?.onDismiss?.();
        removeFromPool(setModalPool, modal);
        config?.afterClose?.();
      },
      onClose: (action: string) => {
        config?.onChooseAction?.(action);
        removeFromPool(setModalPool, modal);
        config?.afterClose?.();
      },
      rightImage,
      leftImage,
    };
    config?.onOpen?.();

    addInPool(setModalPool, modal);
  }, []);

  const showNotification = React.useCallback(
    (config: BaSeDialogNotificationConfig) => {
      const {
        color,
        message,
        supportMessage,
        showCloseButton,
        secondsToClose,
        action,
        icon,
        progressAnimationType,
      } = config;
      const id = getNextCounter(idSequence);
      const notification: BaSeDialogNotificationInterface = {
        id,
        icon,
        color,
        message,
        supportMessage,
        showCloseButton,
        secondsToClose,
        progressAnimationType,
        action,
        onClose: (forced: boolean) => {
          if (forced) {
            config?.onForceClose?.();
          } else {
            config?.onAutoClose?.();
          }
          removeFromPool(setNotificationPool, notification);
          config?.afterClose?.();
        },
        onAction: () => {
          config?.onActionClose?.();
          removeFromPool(setNotificationPool, notification);
          config?.afterClose?.();
        },
      };
      config?.onOpen?.();
      addInPool(setNotificationPool, notification);
    },
    [],
  );

  const showTutorial = React.useCallback(
    (config: BaSeDialogTutorialConfig) => {
      const id = getNextCounter(idSequence);
      const tutorial: BaSeDialogTutorialInterface = {
        ...config,
        id,
        onClose: () => {
          const willClose = config?.beforeClose?.();
          if (willClose === false) {
            return;
          }
          removeFromPool(setTutorialPool, tutorial);
          config?.afterClose?.();
        },
      };
      addInPool(setTutorialPool, tutorial);
    },
    [tutorialPool],
  );

  return (
    <BaSeDialogContext.Provider
      value={{
        DialogContainer: (
          <DialogContainer
            modalPool={modalPool}
            tutorialPool={tutorialPool}
            notificationPool={notificationPool}
          />
        ),
        showModal,
        showNotification,
        showTutorial,
      }}
    >
      {children}
    </BaSeDialogContext.Provider>
  );
};

export const BaSeDialogConsumer = BaSeDialogContext.Consumer;

export const BaSeDialogContainer: React.FC = () => (
  <BaSeDialogConsumer>{({ DialogContainer: DC }) => DC}</BaSeDialogConsumer>
);
