import React from 'react';
import { BaSeProgressElement } from '../elements/progress-element';
import { ProgressDirectionType } from '../components/loading/progress/progress-props';

interface ProgressProps {
  closeOnTimeout?: boolean;
  loadingDuration: number;
  type?: ProgressDirectionType;
  onFinish?: () => void;
  ref: React.ForwardedRef<BaSeProgressElement>;
}

interface ProgressOutput {
  isRunning: boolean;
  loading: boolean;
  progress: number;
}

export function useProgress({
  loadingDuration,
  type,
  onFinish,
  ref,
}: ProgressProps): ProgressOutput {
  const [isRunning, setIsRunning] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const initialProgress = type === 'filling' ? 1 : 99;
  const [progress, setProgress] = React.useState(initialProgress);
  const progressOffset = type === 'filling' ? progress + 1 : progress - 1;

  const resume = React.useCallback(() => {
    if (loadingDuration > 0) {
      setIsRunning(true);
    }
  }, [loadingDuration]);

  const pause = React.useCallback(() => {
    if (loadingDuration > 0) {
      setIsRunning(false);
    }
  }, [isRunning, loadingDuration]);

  React.useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      if (loading && isRunning) {
        setProgress(progressOffset);
      }
    }, loadingDuration / 100);

    if (progress === 0 || progress === 100) {
      if (onFinish && loading) {
        onFinish();
        clearTimeout(loadingTimeout);
      }
      setLoading(false);
    }

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [progress, loading, progressOffset, loadingDuration, isRunning]);

  React.useImperativeHandle(ref, () => ({ pause, resume }));

  return {
    progress,
    loading,
    isRunning,
  };
}
