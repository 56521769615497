import * as React from 'react';
import { version as BaSeVersion } from '../../package.json';
import { BaSeConfig } from '../config/config';

const ELEMENT = 'style' as const;
const TYPE = 'reset-css' as const;

function getElement(): HTMLStyleElement | null {
  return document.querySelector(
    ELEMENT +
      `[data-type="${TYPE}"]` +
      `[data-design-system="BaSe@${BaSeVersion}"]`,
  );
}

function notInjected(): boolean {
  return !getElement();
}

function inject(): void {
  const element = document.createElement(ELEMENT);
  element.dataset.designSystem = `BaSe@${BaSeVersion}`;
  element.dataset.type = TYPE;
  element.innerText = `@import url('${BaSeConfig.staticAssetsUrl}/css/reset.css');`;
  document.head.appendChild(element);
}

function addResetCss(): void {
  if (notInjected()) {
    inject();
  }
}

function removeResetCss(): void {
  getElement()?.remove();
}

export function useResetCss(flag: boolean): void {
  React.useEffect(() => (flag ? addResetCss() : removeResetCss()), [flag]);
}
