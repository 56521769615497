import { css } from 'styled-components';
import { BaSeTheme } from '.';

export const BaSeScrollbarCss = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: ${BaSeTheme.colors.institucionais.cinzaSebrae90};
    border-radius: 100px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
    border-radius: 100px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: ${BaSeTheme.colors.institucionais.cinzaSebrae10};
  }
`;
