import * as React from 'react';
import { BaSeI18nConsumer } from '../../../contexts/i18n';
import { BaSeTheme } from '../../../theme';
import { Color } from '../../../utils/color-transformation/color';
import { getFormattedDataset } from '../../../utils/dataset-utils';
import { BaSeIcon } from '../../image/icon';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import { BaSeSmall2 } from '../../typography/small/small2';
import { ButtonProps } from '../button-props';
import {
  iconButtonBorderRadius,
  iconButtonIconMapSize,
  iconButtonMapStyle,
  mapCustomColorsIconButtonValues,
} from '../map-button-style';
import * as Styled from './icon-button-styled';

const extraSmallIconSize = { iconSize: 12 };

const widthMap = {
  'mega-small': 1,
  'extra-small': 1.25,
  small: 2,
  medium: 2.5,
  big: 3,
};

export interface IconButtonProps
  extends Omit<
    ButtonProps,
    | 'textColor'
    | 'direction'
    | 'isBig'
    | 'isBold'
    | 'leftIcon'
    | 'descriptionLeftIcon'
    | 'rightIcon'
    | 'descriptionRightIcon'
    | 'spacedIcons'
    | 'width'
    | 'loadingText'
    | 'size'
    | 'tooltip'
    | 'popover'
  > {
  size?: 'mega-small' | 'extra-small' | 'small' | 'medium' | 'big';
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export const BaSeIconButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(
  (
    {
      id,
      name,
      dataset,
      nameIcon,
      descriptionIcon,
      value,
      type = 'primary',
      color = BaSeTheme.components.button.colors.default,
      shape = 'circle',
      sizeIcon = 'small',
      size = 'medium',
      isDisabled = false,
      buttonType = 'button',
      sideButton = 'none',
      autoFocus = false,
      isLoading = false,
      children,
      onClick,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
    },
    ref,
  ) => {
    if (!nameIcon && !value) {
      throw Error(
        '[BaSeIconButton] error: Nome do ícone ou um valor textual é obrigatório!',
      );
    }

    const borderRadius =
      iconButtonBorderRadius[shape] ?? iconButtonBorderRadius.circle;

    const { iconSize } = React.useMemo(
      () =>
        size === 'extra-small' || size === 'mega-small'
          ? extraSmallIconSize
          : iconButtonIconMapSize[sizeIcon] ?? iconButtonIconMapSize.medium,
      [size, sizeIcon],
    );

    const padding = React.useMemo(
      () =>
        // TODO molhorar código
        size === 'big'
          ? (40 - iconSize) / 2 + 4
          : size === 'medium'
            ? (40 - iconSize) / 2
            : size === 'small' || size === 'extra-small'
              ? 4
              : 1.5,
      [size, iconSize],
    );

    const width = React.useMemo(
      () => widthMap?.[size ?? 'medium'] ?? 0,
      [size],
    );

    if (width <= 0) {
      throw Error(
        `[BaSeIconButton] error: Informe o "size" com uma das opções:
          - "mega-small"
          - "extra-small"
          - "small"
          - "medium"
          - "big"`,
      );
    }

    const colorIsMapped =
      color === BaSeTheme.components.button.colors.default ||
      color === BaSeTheme.components.button.colors.negative ||
      color === BaSeTheme.components.button.colors.destructive ||
      color === BaSeTheme.components.button.colors.confirmation;

    const colorIsNegative =
      color === BaSeTheme.components.button.colors.negative;

    const getColorsAtributes = () => {
      if (color) {
        if (!colorIsMapped) {
          if (Color.validateColor(color as string)) {
            return mapCustomColorsIconButtonValues(color as string)?.[type];
          }
          return iconButtonMapStyle('default')[type]?.standard;
        }
        return iconButtonMapStyle(color as string)[type]?.standard;
      }
      return iconButtonMapStyle('default')[type]?.standard;
    };

    const typeMapped = colorIsNegative
      ? isDisabled
        ? iconButtonMapStyle('default')[type]?.negative.disabled
        : iconButtonMapStyle('default')[type]?.negative
      : isDisabled
        ? iconButtonMapStyle('default')[type]?.standard.disabled
        : getColorsAtributes();

    const {
      background,
      border,
      backgroundOnFocus,
      iconColor,
      hoverColor,
      boxShadowFocus,
      borderHoverColor,
      iconHoverColor,
    } = typeMapped;

    const [isHover, setIsHover] = React.useState<boolean>(false);

    const formattedDataset = getFormattedDataset(dataset);

    return (
      <Styled.IconButton
        id={id}
        name={name}
        {...formattedDataset}
        ref={ref}
        width={width}
        autoFocus={autoFocus}
        disabled={isDisabled}
        type={buttonType}
        backgroundOnFocus={backgroundOnFocus}
        border={isHover && !!borderHoverColor ? borderHoverColor : border}
        borderRadius={borderRadius}
        backgroundColor={background}
        backgroundOnHover={hoverColor}
        boxShadowOnFocus={
          size.includes('small')
            ? boxShadowFocus.replace('3', '2')
            : boxShadowFocus
        }
        padding={border === 'none' ? padding : padding - 1}
        sideButton={sideButton}
        onMouseEnter={(e) => {
          setIsHover(true);
          onMouseEnter?.(e);
        }}
        onMouseLeave={(e) => {
          setIsHover(false);
          onMouseLeave?.(e);
        }}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <BaSeI18nConsumer>
          {({ getMessage }) =>
            isLoading ? (
              <BaSeLoadingSpinner
                diameter={iconSize}
                color={isHover && !!iconHoverColor ? iconHoverColor : iconColor}
                description={getMessage('loading.description')}
                disabled={isDisabled}
                type="tertiary"
              />
            ) : nameIcon ? (
              <BaSeIcon
                description={
                  descriptionIcon?.trim() ||
                  getMessage('buttonIcon.defaultDescription')
                }
                name={nameIcon}
                size={iconSize}
                color={isHover && !!iconHoverColor ? iconHoverColor : iconColor}
                colorTransition="fast"
              />
            ) : (
              <div
                style={{
                  width: iconSize,
                  height: iconSize,
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <BaSeSmall2
                  color={
                    isHover && !!iconHoverColor ? iconHoverColor : iconColor
                  }
                >
                  {value}
                </BaSeSmall2>
              </div>
            )
          }
        </BaSeI18nConsumer>
        {children}
      </Styled.IconButton>
    );
  },
);

BaSeIconButton.displayName = 'BaSeIconButton';
