import * as React from 'react';

export interface AlertCoordinates {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
}

export type AlertDirection = 'top' | 'right' | 'bottom' | 'left' | 'neutral';

export function useCoordinatesFromBuddyAndDirectionToAlert(
  buddyRef?: React.RefObject<Element>,
  direction?: AlertDirection,
  coordinates?: AlertCoordinates,
): AlertCoordinates {
  const getCoordinates = React.useCallback(() => {
    if (!buddyRef?.current) {
      return coordinates ?? {};
    }

    const rect = buddyRef?.current?.getBoundingClientRect?.();

    if (!rect) {
      throw Error(
        '[useCoordinatesFromBuddyAndDirectionToAlert]: Não foi possível recuperar as informações de tamanho e posição do "buddyRef"',
      );
    }

    if (!direction || direction === 'neutral') {
      throw Error(
        `[useCoordinatesFromBuddyAndDirectionToAlert]: Caso tenha "buddyRef",
        informe uma direção aqui com a prop "direction" entre:
          - top;
          - left;
          - right; ou
          - bottom.`,
      );
    }

    const triangleHeight = 8;
    const unset = 'unset';
    const height = rect.height + '';
    const heightPlusTriangleHeight = `${rect.height + triangleHeight}px`;
    const widthPlusTriangleHeight = `${rect.width + triangleHeight}px`;
    const coords = {
      top: {
        top: unset,
        bottom: heightPlusTriangleHeight,
        left: unset,
        right: unset,
      },
      bottom: {
        top: heightPlusTriangleHeight,
        bottom: unset,
        left: unset,
        right: unset,
      },
      left: {
        top: height,
        bottom: height,
        left: unset,
        right: widthPlusTriangleHeight,
      },
      right: {
        top: height,
        bottom: height,
        left: widthPlusTriangleHeight,
        right: unset,
      },
    };

    return { ...coords[direction], ...coordinates };
  }, [buddyRef?.current, coordinates, direction]);

  return React.useMemo(() => getCoordinates(), [getCoordinates]);
}
