import styled from 'styled-components';

export const ProgressCircleWrapper = styled.div`
  position: relative;
  display: inline-block;
  .BaSe--progress-circle-progress-line {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }
`;
