import { toKebabCase } from './string-utils';

/**
 * Formata dataset no padrão kebab-case
 * @param dataset O dataset em camelCase
 * @returns Um mapa de data DOMString em kebab-case
 */
export const getFormattedDataset = (dataset?: DOMStringMap) => {
  if (
    dataset === null ||
    dataset === undefined ||
    Object.keys(dataset).length === 0
  ) {
    return undefined;
  }

  return Object.entries(dataset).reduce((acc, [key, value]) => {
    acc[`data-${toKebabCase(key)}`] = value;
    return acc;
  }, {} as DOMStringMap);
};
