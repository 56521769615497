import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledHeading5 } from './heading5-styled';

export const BaSeHeading5: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={1.2}
    lineHeight={1.2}
    wrapper={StyledHeading5}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeHeading5.displayName = 'BaSeHeading5';
