import * as React from 'react';
import { BasicTypographyProps, BasicLink } from './typography-props';
import { LinkWrapper } from './typography-styled';
import {
  TypographyLinkMapper,
  TypographyCustomLinkMapper,
  TypographyTypesColor,
  TypographyCustomTypesColor,
} from './typography-mapper';

interface LinkProps extends BasicTypographyProps {
  isUnderline: boolean;
  link?: string;
  isDisabled: boolean;
  target?: string;
  availableColors?: ReturnType<typeof TypographyCustomLinkMapper>;
}

function Link({
  wrapper: Wrapper,
  availableColors,
  children,
  fontSize,
  lineHeight,
  isDisabled,
  isUnderline,
  link,
  target,
  ...other
}: React.PropsWithChildren<LinkProps>) {
  return (
    <LinkWrapper
      fontSize={fontSize}
      lineHeight={lineHeight}
      isDisabled={isDisabled}
      isUnderline={isUnderline}
      href={!!link ? link : undefined}
      colors={availableColors}
      target={target}
    >
      <Wrapper
        color={availableColors?.foreground}
        {...(other as BasicTypographyProps)}
      >
        {children}
      </Wrapper>
    </LinkWrapper>
  );
}

export const BaSeTypographyWrapper: React.FC<BasicTypographyProps> = (
  props,
) => {
  const {
    fontSize,
    lineHeight,
    children,
    color,
    type,
    link,
    wrapper: Wrapper,
    // eslint-disable-next-line comma-dangle
    ...other
  } = props;
  const availableColors = !!color
    ? TypographyLinkMapper[color as keyof typeof TypographyLinkMapper] ??
      TypographyCustomLinkMapper(color as string)
    : undefined;
  const typeColor = !!type
    ? TypographyTypesColor[
        (color as keyof typeof TypographyLinkMapper) ?? 'default'
      ]?.[type] ?? TypographyCustomTypesColor(color as string)?.[type]
    : null;

  const basicLink = React.useMemo(() => link as BasicLink, [link]);
  if (!!link) {
    if (typeof link === 'string') {
      return (
        <Link
          availableColors={availableColors}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          type={type}
          wrapper={Wrapper}
          isUnderline={true}
          link={link}
          isDisabled={false}
        >
          {children}
        </Link>
      );
    }

    if (!!basicLink?.href) {
      return (
        <Link
          availableColors={availableColors}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          type={type}
          wrapper={Wrapper}
          isUnderline={!!basicLink?.isUnderline}
          link={basicLink?.isDisabled ? '' : basicLink?.href ?? ''}
          isDisabled={!!basicLink?.isDisabled}
          target={basicLink?.target}
        >
          {children}
        </Link>
      );
    }

    if (!!basicLink?.element && React.isValidElement(basicLink?.element?.())) {
      return React.cloneElement(
        basicLink?.element?.(),
        basicLink?.isDisabled ? { to: '' } : {},
        <Link
          availableColors={availableColors}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          type={type}
          wrapper={Wrapper}
          isUnderline={!!basicLink?.isUnderline}
          link={
            !!basicLink?.isDisabled
              ? ''
              : (basicLink?.element?.()?.props as { to: string })?.to ??
                (basicLink?.element?.()?.props as { href: string })?.href ??
                '#'
          }
          isDisabled={!!basicLink?.isDisabled}
          target={basicLink?.target}
        >
          {children}
        </Link>,
      );
    }

    if (React.isValidElement((link as () => JSX.Element)?.())) {
      return React.cloneElement(
        (link as () => JSX.Element)?.(),
        {},
        <Link
          availableColors={availableColors}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          type={type}
          wrapper={Wrapper}
          isUnderline={true}
          link={
            ((link as () => JSX.Element)?.()?.props as { to: string })?.to ??
            ((link as () => JSX.Element)?.()?.props as { href: string })
              ?.href ??
            '#'
          }
          isDisabled={false}
        >
          {children}
        </Link>,
      );
    }
  }

  return (
    <Wrapper
      color={typeColor ?? availableColors?.foreground}
      {...(other as Omit<BasicTypographyProps, 'wrapper'>)}
    >
      {children}
    </Wrapper>
  );
};

BaSeTypographyWrapper.displayName = 'BaSeTypographyWrapper';
