import styled from 'styled-components';
import { BaSeTheme } from '../../theme';

interface WrapperStyledProps {
  wrapperHeight: number;
}

export const WrapperStyled = styled.div.attrs({
  className: 'BaSe--account-profile',
})<WrapperStyledProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  position: relative;
  overflow: visible;
  height: ${(props) => props.wrapperHeight};
  padding: 0;
`;
