import * as React from 'react';
import {
  BaSeDialogNotificationColorType,
  BaSeDialogNotificationInterface,
} from '../../contexts/dialog';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeProgressElement } from '../../elements/progress-element';
import { useNextCounterId } from '../../hooks/next-id';
import { BaSeTheme } from '../../theme';
import { getAlertColorFromType } from '../../theme/maps-colors';
import { transformLightness } from '../../utils/color-transformation/transform-lightness';
import { idGenerator } from '../../utils/id-generator';
import { BaSeButton } from '../button/button/button';
import { BaSeIconButton } from '../button/shape-button/icon-button';
import { BaSeIcon } from '../image/icon';
import { BaSeProgressBar } from '../loading/progress/progress-bar';
import { BaSeProgressCircle } from '../loading/progress/progress-circle';
import { BaSeHeading6 } from '../typography/heading/heading6';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import {
  mapCustomColorsNotificationValues,
  mapNotificationValues,
} from './map-notification-style';
import { NotificationDialog } from './notification-styled';

enum ExitingMode {
  'forced',
  'auto',
  'action',
}

const iconColorMap: { [k in BaSeDialogNotificationColorType]: string } = {
  attention: 'exclamation-triangle',
  default: 'info-circle',
  destructive: 'exclamation-octagon',
  confirmation: 'check-circle',
};

const idSequence = idGenerator();

export const BaSeNotification = ({
  color,
  icon,
  message,
  supportMessage,
  showCloseButton = true,
  secondsToClose = 5,
  progressAnimationType: progressAnimationTypeExternal,
  action,
  onClose,
  onAction,
}: Omit<BaSeDialogNotificationInterface, 'id'>) => {
  const counterId = useNextCounterId(idSequence);

  const iconName = React.useMemo(() => {
    const iconNameDefault = iconColorMap[color];
    return icon === false ? undefined : icon ?? iconNameDefault;
  }, [color, icon]);

  const progressAnimationType = React.useMemo(
    () => progressAnimationTypeExternal ?? (showCloseButton ? 'circle' : 'bar'),
    [progressAnimationTypeExternal, showCloseButton],
  );

  const [exiting, setExiting] = React.useState<ExitingMode>();
  const progressRef = React.useRef<BaSeProgressElement>(null);

  const autoClose = React.useCallback(() => {
    setExiting(ExitingMode.auto);
  }, []);

  const forceClose = React.useCallback(() => {
    setExiting(ExitingMode.forced);
  }, []);

  const actionClose = React.useCallback(
    () => setExiting(ExitingMode.action),
    [],
  );

  const pause = React.useCallback(() => {
    progressRef.current?.pause();
  }, []);

  const resume = React.useCallback(() => {
    progressRef.current?.resume();
  }, []);

  React.useEffect(() => {
    if (exiting === ExitingMode.forced) {
      const timeout = setTimeout(
        () => onClose(true),
        BaSeTheme.transitions.durationFastInMilliseconds,
      );
      return () => clearTimeout(timeout);
    }
    if (exiting === ExitingMode.auto) {
      const timeout = setTimeout(
        () => onClose(false),
        BaSeTheme.transitions.durationFastInMilliseconds,
      );
      return () => clearTimeout(timeout);
    }
    if (exiting === ExitingMode.action) {
      const timeout = setTimeout(
        () => onAction(),
        BaSeTheme.transitions.durationFastInMilliseconds,
      );
      return () => clearTimeout(timeout);
    }
    return;
  }, [exiting, onAction, onClose]);

  React.useEffect(() => {
    const shownTimeout = setTimeout(
      () =>
        navigator?.vibrate?.(BaSeTheme.transitions.durationFastInMilliseconds),
      0,
    );
    return () => clearTimeout(shownTimeout);
  }, []);

  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.confirmation ||
    color === BaSeTheme.components.button.colors.attention;

  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        return mapCustomColorsNotificationValues(color).primary;
      } else {
        return mapNotificationValues(color).primary?.generalColors;
      }
    } else {
      return mapNotificationValues('default').primary.generalColors;
    }
  };

  const colorValues = getColorsAtributes();
  const { getMessage } = React.useContext(BaSeI18nContext);
  const progressLoadingDuration = React.useMemo(
    () => secondsToClose * 1000,
    [secondsToClose],
  );
  const progressTrackColor = React.useMemo(
    () => transformLightness(colorValues.color, 0.8),
    [colorValues],
  );

  const closeButton = React.useMemo(
    () => (
      <BaSeIconButton
        nameIcon="close-big"
        descriptionIcon={getMessage('buttonIcon.iconDescription.close')}
        buttonType="button"
        shape="circle"
        size="small"
        sizeIcon="small"
        type="tertiary"
        color={colorValues.color}
        onClick={() => {
          forceClose();
        }}
      />
    ),
    [colorValues.color, forceClose, getMessage],
  );

  const progressCircle = React.useMemo(
    () => (
      <BaSeProgressCircle
        ref={progressRef}
        loadingDuration={progressLoadingDuration}
        indicatorColor={colorValues.color}
        trackColor={progressTrackColor}
        size={32}
        trackWidth={3}
        indicatorWidth={3}
        type="emptying"
        closeOnTimeout={false}
        onFinish={() => autoClose()}
      >
        {closeButton}
      </BaSeProgressCircle>
    ),
    [
      progressRef,
      progressLoadingDuration,
      colorValues,
      progressTrackColor,
      autoClose,
      closeButton,
    ],
  );

  const progressBar = React.useMemo(
    () => (
      <BaSeProgressBar
        backgroundColor={getAlertColorFromType(color).background}
        foregroundColor={getAlertColorFromType(color).foreground}
        loadingDuration={progressLoadingDuration}
        onFinish={autoClose}
        ref={progressRef}
      />
    ),
    [color, progressLoadingDuration, autoClose],
  );

  return (
    <NotificationDialog
      className={`BaSe--notification-dialog ${
        exiting !== undefined ? 'exiting' : ''
      }`}
      color={colorValues.color}
      backgroundColor={colorValues.style.bgC}
      role="dialog"
      aria-modal="false"
      aria-labelledby={`BaSe--notification-title-${counterId}`}
      aria-describedby={`BaSe--notification-description-${counterId}`}
      onFocusCapture={() => pause()}
      onBlurCapture={() => resume()}
      onMouseEnter={() => pause()}
      onMouseLeave={() => resume()}
    >
      {iconName && (
        <span className="icon-wrapper">
          <BaSeIcon
            color={colorValues.color}
            name={iconName}
            size={26}
            description={getMessage('notification.iconDescription')}
          />
        </span>
      )}
      <div className="message">
        <span id={`BaSe--notification-title-${counterId}`}>
          <BaSeHeading6 isBold={true}>{message}</BaSeHeading6>
        </span>
        {supportMessage && (
          <span id={`BaSe--notification-description-${counterId}`}>
            <BaSeParagraph withNewLineWrap={true}>
              {supportMessage}
            </BaSeParagraph>
          </span>
        )}
      </div>

      {action && (
        <BaSeButton
          buttonType="button"
          size="small"
          type="tertiary"
          color={colorValues.color}
          value={action}
          onClick={() => actionClose()}
        />
      )}

      {(secondsToClose === 0 ||
        (secondsToClose > 0 && progressAnimationType !== 'circle')) &&
        showCloseButton &&
        closeButton}

      {secondsToClose > 0 &&
        progressAnimationType === 'circle' &&
        progressCircle}

      {secondsToClose > 0 && progressAnimationType === 'bar' && progressBar}
    </NotificationDialog>
  );
};

BaSeNotification.displayName = 'BaSeNotification';
