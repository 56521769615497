import styled from 'styled-components';
import { BaSeTheme } from '../../theme';

export const TutorialContainer = styled.div`
  z-index: ${BaSeTheme.stackOrder.overlay * 2};
  &,
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &::before {
    content: '';
    background: ${BaSeTheme.colors.institucionais.cinzaSebrae20};
    opacity: 0.5;
  }
`;
