import { ThemeStackOrderInterface } from './theme-interface';

export const BaSeStackOrder: ThemeStackOrderInterface = {
  default: 0,
  normal: 1,
  aboveNormal: 10,
  belowOverlay: 100,
  overlay: 1000,
  aboveOverlay: 9999,
};
