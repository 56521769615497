import * as React from 'react';
import { BaSeStaticAsset } from '../static-asset/static-asset';
import { ImageProps } from './image-props';
import { extractWidthHeight } from './image-extract-width-height';

export const BaSeIcon: React.FC<Omit<ImageProps, 'width' | 'height'>> = ({
  name,
  description,
  size,
  color,
  colorTransition,
  onClick,
}) => (
  <BaSeStaticAsset
    {...extractWidthHeight(size)}
    classIdentifier="BaSe--icon"
    description={description}
    path={`images/icons/${name}.svg`}
    color={color}
    colorTransition={colorTransition}
    onClick={onClick}
  />
);

BaSeIcon.displayName = 'BaSeIcon';
