import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledHeading4 } from './heading4-styled';

export const BaSeHeading4: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={1.44}
    lineHeight={1.2}
    wrapper={StyledHeading4}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeHeading4.displayName = 'BaSeHeading4';
