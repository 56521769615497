import * as React from 'react';
import { numberWithUnit } from '../../../utils/string-utils';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeStaticAsset } from '../../static-asset/static-asset';
import { getLogoPath, LogoSebraeProps } from './logo-utils';

export const BaSeLogoSebraeRestrict: React.FC<LogoSebraeProps> = ({
  onClick,
  width = 'auto',
  easterEgg = false,
}) => {
  const { getMessage } = React.useContext(BaSeI18nContext);
  return (
    <BaSeStaticAsset
      classIdentifier="BaSe--logo-sebrae-restrict"
      description={getMessage('logo.description')}
      path={getLogoPath(easterEgg, 'images/logos/sebrae_logo-restrict')}
      onClick={onClick}
      width={numberWithUnit(width)}
    />
  );
};

BaSeLogoSebraeRestrict.displayName = 'BaSeLogoSebraeRestrict';
