import {
  ThemeButtonComponentInterface,
  ThemeComponentsInterface,
} from './theme-interface';

const button: ThemeButtonComponentInterface = {
  colors: {
    default: 'default',
    padrao: 'default',
    destructive: 'destructive',
    destrutivo: 'destructive',
    confirmation: 'confirmation',
    confirmacao: 'confirmation',
    negative: 'negative',
    negativo: 'negative',
    attention: 'attention',
    atencao: 'attention',
  },
};

export const BaSeComponents: ThemeComponentsInterface = {
  button,
};
