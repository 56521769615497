import * as React from 'react';
import { BaSeI18nConsumer } from '../../contexts/i18n';
import { BaSeTheme } from '../../theme';
import { BaSeIconButton } from '../button/shape-button/icon-button';

interface AvatarOrIconAndNameAndEmailProps {
  avatar: string;
  icon?: string;
  name: string;
  email?: string;
  onClose?: () => void;
}

export const AvatarOrIconAndNameAndEmail: React.FC<AvatarOrIconAndNameAndEmailProps> =
  React.memo((props: AvatarOrIconAndNameAndEmailProps) => {
    const [hasAvatar, setHasAvatar] = React.useState(true);

    React.useEffect(() => setHasAvatar(!!props.avatar), [props.avatar]);

    return (
      <>
        {hasAvatar ? (
          <BaSeI18nConsumer>
            {({ getMessage }) => (
              <img
                loading="lazy"
                className="BaSe--account-profile-avatar"
                src={props.avatar}
                alt={getMessage('avatarImage.description')}
                onError={() => setHasAvatar(false)}
              />
            )}
          </BaSeI18nConsumer>
        ) : (
          <span
            className="BaSe--account-profile-name-abbr-icon"
            aria-hidden="true"
          >
            {props.icon ?? '😃'}
          </span>
        )}
        {props.email ? (
          <span className="BaSe--account-profile-name-with-close">
            <span className="BaSe--account-profile-name">
              <span>{props.name}</span>
              <div className="BaSe--account-profile-close-button">
                <BaSeI18nConsumer>
                  {({ getMessage }) => (
                    <BaSeIconButton
                      nameIcon="close-big"
                      descriptionIcon={getMessage(
                        'buttonIcon.iconDescription.close',
                      )}
                      buttonType="button"
                      shape="circle"
                      size="small"
                      sizeIcon="small"
                      type="tertiary"
                      color={BaSeTheme.colors.institucionais.cinzaSebrae75}
                      onClick={() => props?.onClose?.()}
                    />
                  )}
                </BaSeI18nConsumer>
              </div>
            </span>
            <small className="BaSe--account-profile-email">{props.email}</small>
          </span>
        ) : (
          <span>
            <span className="BaSe--account-profile-name">{props.name}</span>
          </span>
        )}
      </>
    );
  });

AvatarOrIconAndNameAndEmail.displayName = 'AvatarOrIconAndNameAndEmail';
